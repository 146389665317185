@import "../../../assets/scss/Variables.scss";

.alectify-profile-modal-container {
  top: 20px;
  .profile-image-container {
    text-align: center;
  }

  .image-wrapper {
    position: relative;
    display: inline-block;
  }

  .camera-icon {
    position: absolute;
    top: 135px;
    right: 25px;
    background-color: white;
    padding: 7px;
    border-radius: 50%;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
  }

  .contact-profile-image {
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 50%;
    object-fit: cover;
    height: 155px;
    width: 155px;
    border: 1px solid #0954f1;
  }
  .ant-modal-content {
    border-radius: 0px;
    padding: 0px;
    .ant-modal-close {
      display: none;
    }
    .ant-modal-title {
      background: var(--sample-blue, $alectify-primary-blue);
      display: flex;
      height: 45px;
      padding: 20px 25px;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: white;
    }
    .ant-modal-body {
      padding: 20px 24px;
    }
    .ant-modal-footer {
      border-top: 1px solid #e4e4e4;
      padding: 16px 20px;
    }
  }

  .alectify-input-profile {
    input {
      height: 45px;
    }
    label {
      color: #545454;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.00875rem;
    }
  }

  .email-container {
    padding: 0.3125rem 0.625rem;
    gap: 0.3125rem;
    border-radius: 0.25rem;
    background: #e8f1fd;
    display: flex;
    text-align: center;
    span {
      margin-left: 6px;
    }
  }

  .profile-heading {
    color: #252525;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.01rem;
  }
}
