.alectify-avatar-external-user {
  border-color: red !important;
}

.external-user-arrow-icon {
  font-size: 10px;
  color: white;
  background-color: red;
  border-radius: 50%;
  padding: 2px;
}
