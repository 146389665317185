@import "../../../../assets/scss/Variables.scss";

.sub-project-card {
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.16);
  }
  .draft-container {
    background-color: #faddd4;
    padding: 5px;
    border-radius: 10px;
    font-size: 12px;
    color: #e33507;
    font-weight: 500;
    border: 1px solid #e33507;
  }
  .draft-edit-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header {
    margin-bottom: 0.2em;

    h1 {
      font-size: 0.9rem;
      margin: 0;
    }
  }

  .Alectify-subProject-card:hover {
    text-decoration: underline;
  }

  .body {
    margin-bottom: 15px;

    div.ant-typography {
      color: $text-color-light-grey;
      font-size: 14px;
      margin-bottom: 14px;
    }
  }

  .footer {
    padding-top: 10px;
    border-top: 1px solid #e8f1fd;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    .ant-tag {
      display: inline-flex;
      padding: 3px 8px;
      justify-content: center;
      align-items: center;
      gap: 3px;
      border-radius: 10000px;

      & > span {
        vertical-align: middle;
        font-size: 14px;
        margin-left: 5px;
      }

      img {
        vertical-align: middle;
      }
      svg {
        height: 18px !important;
      }
    }
  }
  .alectify-sub-project-card-description {
    .ant-typography {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
