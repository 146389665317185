@import "../../../assets/scss/Variables.scss";

@mixin hoverAndActiveStyles {
  color: $alectify-primary-blue;

  .ant-tabs-tab-btn {
    color: $alectify-primary-blue;
  }

  svg {
    path[fill="#141B34"] {
      fill: $alectify-primary-blue;
    }

    path[stroke="#141B34"] {
      stroke: $alectify-primary-blue;
    }

    rect[stroke="#141B34"] {
      stroke: $alectify-primary-blue;
    }
  }
}

.sub-project-tabs {
  .ant-tabs-nav {
    margin-bottom: 8px;
  }
  .ant-tabs-tab {
    font-size: 14px;
    padding: 10px 15px;

    svg {
      vertical-align: middle;
      margin-right: 0px;
      height: 18px;
    }

    & + .ant-tabs-tab {
      margin-left: 0px;
    }

    &:hover {
      @include hoverAndActiveStyles;
    }

    &.ant-tabs-tab-active {
      @include hoverAndActiveStyles;
    }
  }
}

.alectify-tabs {
  .ant-tabs-nav {
    &::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    background-color: $alectify-gray-color;
    border-radius: 6px !important;
    padding: 7px 15px;
    border-bottom: 0px;
    font-size: 14px;

    & + .ant-tabs-tab {
      margin-left: 11px;
    }

    svg {
      vertical-align: middle;
      margin-right: 0px;
      height: 18px;
    }

    .ant-tabs-tab-btn {
      transition: none;
    }

    &.ant-tabs-tab-active {
      background-color: $alectify-primary-blue !important;
      color: #fff !important;

      svg {
        path[fill="#4C4C4C"] {
          fill: #fff !important;
        }

        path[stroke="#4C4C4C"] {
          stroke: #fff !important;
        }

        rect[stroke="#4C4C4C"] {
          stroke: #fff !important;
        }
      }

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }

    &:hover {
      background-color: $alectify-primary-blue;
      color: #fff;

      .ant-tabs-tab-btn {
        color: #fff;
      }

      svg {
        path[fill="#4C4C4C"] {
          fill: #fff !important;
        }

        path[stroke="#4C4C4C"] {
          stroke: #fff !important;
        }

        rect[stroke="#4C4C4C"] {
          stroke: #fff !important;
        }
      }
    }
  }
}
