.alectify-phone-number {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  input {
    height: 45px;
    color: #232323;
    border-radius: 0 6px 6px 0 !important;
  }
  .ant-select-selector {
    height: 45px !important;
  }

  .ant-select-single .ant-select-selector {
    border-radius: 6px 0 0 6px !important;
  }
}

.alectify-contact-phone-number {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  input {
    height: 45px;
    color: #232323;
    border-radius: 0 30px 30px 0 !important;
  }
  .ant-select-selector {
    height: 45px !important;
  }

  .ant-select-single .ant-select-selector {
    border-radius: 30px 0 0 30px !important;
  }
}
