@import "../../assets/scss/Variables.scss";

.slider-container {
  margin: 20px;
}

.slick-dots {
  margin-bottom: -14px;
}

.slick-dots.slick-thumb {
  display: flex !important;
  align-items: center;
  margin-top: 20px;
}

.slick-dots.slick-thumb li {
  width: auto;
  margin: 0 5px;
}

.slick-dots.slick-thumb li a {
  display: block;
  border: 2px solid transparent;
  transition: border 0.3s;
  filter: grayscale(100%);
}

.slick-dots.slick-thumb li.slick-active a {
  border-radius: 5px;
  filter: grayscale(0%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slick-next {
  right: 0px;
  background-color: rgba(0, 0, 0, 0.45) !important;
  border-radius: 50%;
}

.slick-prev {
  left: 0px;
  background-color: rgba(0, 0, 0, 0.45) !important;
  border-radius: 50%;
}

.carousel-modal {
  height: 100%;
  .ant-modal-body {
    padding: 0 !important;
    height: 35rem;
  }

  .ant-modal-close {
    color: black !important;
    right: -17px;
    top: -17px;
    background-color: $alectify-white;

    &:hover {
      background-color: $alectify-tertiary-blue;
    }
  }
}

.image-counter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3px;
}

.carousel-item {
  display: flex !important;
  align-items: center;
  justify-content: center;

  .img-carousel {
    width: 100%;
    height: 335px;
    object-fit: cover;
  }
}

.slick-slider {
  .slick-list {
    border-radius: 10px;
  }
}

.custom-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
  &.slick-prev {
    top: 166px;
    &:before {
      display: none;
    }
  }

  &.slick-next {
    right: 10px;
    top: 166px;

    &:before {
      display: none;
    }
  }
}

.empty-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 435px;
  padding: 10px;
}

.avatar-uploader {
  padding-left: 10px;
  .ant-upload {
    height: 50px !important;
    width: 50px !important;
  }
}

.empty-image {
  .ant-upload {
    height: 420px !important;
    width: 100% !important;
    background-color: #f7f4f4;
    border-radius: 10px;
  }

  &:hover {
    .icon-font-size-20 {
      color: $alectify-primary-blue;
    }
  }
}

.upload-items {
  span {
    color: $text-color-light-grey;
  }
}

.plus-icon {
  &:hover {
    .anticon {
      color: $alectify-primary-blue !important;
    }
  }
}

.plus-white-icon {
  .anticon {
    color: white !important;
  }
}

.video-carousel-container {
  margin-top: 70px;
}

.video-thumbnail-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  background-color: rgb(27, 27, 27);
}
