.alectify-autocomplete {
  height: 45px !important;
  .ant-select-selection-placeholder {
    color: #787878 !important;
  }
}
.alectify-organization-autocomplete {
  .ant-select {
    height: 45px !important;
  }
}
