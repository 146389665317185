@import "../../../assets/scss/Variables.scss";

.alectify-detail-card-image {
  text-align: center;
  margin-bottom: 0px;

  .ant-avatar {
    width: 100px;
    height: 100px;
    border: 1px solid $alectify-primary-blue;
  }
}
