.upload-btn-hide {
  display: none;
}

.manageAssets-container {
  width: 80rem !important;
  height: 100%;
  .ant-table-cell:not(:last-child) {
    &::before {
      display: none;
    }
  }
}

.alectify-manage-asset-form-container {
  textarea {
    height: 44px;
  }
  .alectify-manage-asset-form-asset-detail-container {
    .ant-form-item-has-success {
      margin-bottom: 10px !important;
    }
  }
  .alectify-manage-asset-form-radio-buttons {
    .ant-radio-wrapper-disabled {
      color: #737373;
    }
  }
}
