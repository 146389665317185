.report-table {
  transition: all 0.1s ease-in-out;
}
.detail-page {
  transition: all 0.7s ease-in-out;
  opacity: 0;
}

.active-detail-page {
  opacity: 1;
}
