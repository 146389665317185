.alectify-procedure-container {
  .alectify-procedure-toolbar {
    .alectify-procedure-search {
      width: 266px;
      input {
        height: 26px !important;
      }
    }
  }
}

.toggle-button-container {
  margin-left: 10px;
  .active {
    background-color: #0954f1;
    span {
      color: #fff;
    }
  }
}
