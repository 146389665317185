@import "../../../../assets/scss/Variables.scss";

.folder-file-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.creator-container {
  display: flex;
  align-items: center;
  .creator-name-date {
    display: flex;
    color: #232323;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4px;
  }
}

.project-document-link {
  color: rgba(0, 0, 0, 0.88) !important;
  cursor: default;
}

.folder-container {
  display: flex;
  height: 26px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  width: fit-content;
}

.images {
  background: #4380ff;
}

.videos {
  background: #de1212;
}
.final-drawings {
  background: #14c059;
}

.test-reports {
  background: #48566a;
}
.others {
  background: #13c5ec;
}

.others {
  background: #48566a;
}

.instruction-manual {
  background: #04a99f;
}

.service-reports {
  background: #405895;
}

.power-studies {
  background: #7685aa;
}

.electrical-packages {
  background: #ff4d14;
}

.addendums {
  background: #334896;
}

.test-report {
  background: #662d91;
}

.text-link {
  text-decoration: underline;
  color: #0954f1 !important;
  cursor: pointer;
  &:hover {
    font-weight: 700 !important;
  }
}

.asset-package-container {
  display: flex;
  flex-direction: column;
}

.alectify-table-card {
  .ant-table-header {
    table {
      border-spacing: 0px;
    }
  }
  table {
    padding: 0px 4px 0px 4px;
    border-spacing: 0px 8px;
  }

  .ant-checkbox-wrapper {
    border-spacing: 0px;
  }
  tbody {
    tr {
      height: 90px;
      border-radius: 6px;
      border: 1px solid #dae5f3;
      background: #fff;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);

      &:last-child td:last-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:first-child td {
        border-radius: 6px;
      }
      td:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child td:last-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child td {
        border-radius: 6px;
      }
      td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  &.height-auto {
    .ant-table-body {
      table {
        tr {
          height: auto;
        }
      }
    }
  }

  .ant-table-row:nth-child(odd) {
    background: transparent !important;
  }
}
