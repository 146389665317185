@import "../../../../assets/scss/Variables.scss";

.procedure-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  min-height: 210px;
  &:hover {
    box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.16);
  }
  .draft-container {
    background-color: #faddd4;
    padding: 5px;
    border-radius: 10px;
    font-size: 12px;
    color: #e33507;
    font-weight: 500;
    border: 1px solid #e33507;
  }
  .draft-edit-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .procedure-card-img {
    .ant-avatar {
      width: 65px;
      height: 65px;
    }
  }

  .header {
    margin-bottom: 0.5em;

    h1 {
      font-size: 0.9rem;
      margin: 0;
      text-wrap: wrap;
    }
  }

  .body {
    margin-bottom: 15px;

    div.ant-typography {
      color: $text-color-light-grey;
      font-size: 14px;
      margin-bottom: 14px;
    }
  }

  .creator-info {
    padding-top: 7px;
    display: flex;
    justify-content: space-between;
    color: #787878;
    font-size: 13px;
  }

  .footer {
    padding-top: 6px;
    border-top: 1px solid #e8f1fd;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #e8f1fd;
    padding-bottom: 2px;
    .ant-tag {
      display: inline-flex;
      padding: 3px 8px;
      justify-content: center;
      align-items: center;
      gap: 3px;
      border-radius: 10000px;
      margin-bottom: 4px;

      & > span {
        vertical-align: middle;
        font-size: 14px;
        margin-left: 5px;
      }

      img {
        vertical-align: middle;
      }
      svg {
        height: 18px !important;
      }
    }
    .steps-tag {
      @extend .ant-tag;
      color: #000 !important;
      font-size: 13px;
    }
  }
  .alectify-procedure-card-description {
    .ant-typography {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.width-50 {
  width: 50px;
}
.procedure-card-table-img {
  .ant-avatar {
    width: 40px !important;
    height: 40px !important;
  }
}
