@import "../../../assets/scss/Variables.scss";

.audio-player {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  .rhap_time {
    font-size: 12px !important;
  }
  .rhap_container {
    height: 38px;
    padding: 0 10px 0px 0 !important;

    path {
      fill: $alectify-primary-blue !important;
    }
  }

  .rhap_play-pause-button {
    font-size: 28px !important;
    width: 30px !important;
    height: 30px !important;
  }
  .rhap_progress-indicator {
    width: 10px !important;
    height: 10px !important;
    margin-left: -10px !important;
    top: -4px !important;
    background-color: $alectify-primary-blue !important;
  }

  .rhap_container {
    border-radius: 10px !important;
  }

  .rhap_progress-filled {
    background-color: $alectify-primary-blue !important;
  }

  .rhap_controls-section {
    width: 40px !important;
    margin: 0 !important;
    flex: 0 !important;
  }
  .rhap_progress-bar {
    height: 2px !important;
  }
}
