@import "../../../assets/scss/Variables.scss";

.table-filter-modal {
  .ant-modal-header {
    text-align: center;
    padding-bottom: 0.3125rem;
    border-bottom: 1px solid #dddcdc7d;
  }
  .ant-modal-content {
    padding: 0.625rem 24px !important;
  }
  .ant-modal-body {
    padding: 0.9375rem 0px;
  }

  .ant-modal-footer {
    padding-top: 0.625rem;
    border-top: 1px solid #dddcdc7d;
  }
}
.filter-button {
  display: flex;
  color: $alectify-text-color;
  align-items: center;
  justify-content: center;
  border: 1px solid $alectify-border-color;
  width: fit-content;
  padding: 0.3125rem 0.3125rem;
  height: 1.9rem;
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  width: 4.375rem;
  cursor: pointer;

  span {
    margin-left: 0.3125rem;
  }

  &:hover {
    background-color: $alectify-tertiary-blue;

    border: 1px solid $alectify-primary-blue;
  }
}

.active-filter-button {
  background-color: $alectify-tertiary-blue;
  border: 2px solid $alectify-primary-blue;

  span {
    color: $alectify-primary-blue;
  }

  path {
    color: $alectify-primary-blue;
  }
}

.button-filters {
  display: inline-block;
  width: 6.87rem;
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  transition: background-color 0.3s;
  border: 1px solid $alectify-border-color;
  background-color: $alectify-white;
  color: $alectify-text-color;

  &.active {
    background-color: $alectify-tertiary-blue;
    border: 2px solid $alectify-primary-blue;
    color: $alectify-primary-blue;

    &:hover {
      color: $alectify-primary-blue;
      background-color: $alectify-tertiary-blue;
      border: 2px solid $alectify-primary-blue;
    }
  }

  &:hover {
    background-color: $alectify-tertiary-blue;
    border: 1px solid $alectify-primary-blue;
  }
}

.status-container {
  width: 17rem;
  font-size: 0.875rem;
}

.text-link-filters {
  display: flex;
  align-items: baseline;
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.7rem;
  &:hover {
    color: $alectify-primary-blue;
  }
}

.category-select {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.active-select {
  .ant-select-selector {
    color: $alectify-primary-blue;
    padding: 0.3125rem 0.625rem !important;
    border: 2px solid $alectify-primary-blue !important;
    box-shadow: none !important;
    background-color: $alectify-tertiary-blue !important;
    &:hover {
      box-shadow: none !important;
      border: 2px solid $alectify-primary-blue !important;
    }
  }
}

.modal-filters-container {
  :where(.css-dev-only-do-not-override-i0102m).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: 1px solid $alectify-border-color;
    box-shadow: none !important;
    &:hover {
      box-shadow: none !important;
      border: 1px solid $alectify-primary-blue !important;
    }
  }
}

.filter-col {
  height: 2.5rem;
  width: 100rem;
}
