.draggable-button {
  position: fixed;
  width: 55px;
  height: 43px;
  background-color: #0a66ff;
  color: white;
  cursor: grab;
  user-select: none;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease,
    transform 0.3s ease;
  z-index: 9;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  background: linear-gradient(88deg, #0954f1 1%, #ff5fd6 153.41%);
  box-shadow:
    4px 4px 10px rgba(0, 0, 0, 0.25),
    -4px -4px 10px rgba(255, 255, 255, 0.1);

  &:hover {
    background: linear-gradient(88deg, #0954f1 1%, #ff5fd6 153.41%);
    box-shadow:
      6px 6px 15px rgba(0, 0, 0, 0.3),
      -6px -6px 15px rgba(255, 255, 255, 0.15);
    transform: translateY(-2px);
  }
  &:active {
    cursor: grabbing;
    background-color: #0043cc;
    box-shadow:
      2px 2px 5px rgba(0, 0, 0, 0.25),
      -2px -2px 5px rgba(255, 255, 255, 0.1);
    transform: translateY(2px);
  }
}

.button-text {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .arrow-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .second-icon {
      margin-left: -7px;
      margin-right: 7px;
      margin-top: 2px;
    }
  }

  span {
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
  }
}

.arrow-box svg {
  transition: transform 0.3s ease-in-out;
}

@keyframes reverseTypingEffect {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
}

.draggable-button:hover .arrow-box svg:nth-child(1) {
  animation: reverseTypingEffect 1s infinite;
}

.draggable-button:hover .arrow-box svg:nth-child(2) {
  animation: reverseTypingEffect 1s infinite;
  animation-delay: 0.5s;
}
