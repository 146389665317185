@import "../../../assets/scss/Variables.scss";

.marker-container {
  .ant-popover-inner {
    padding: 22px !important;
    .ant-popover-title {
      div {
        margin-bottom: 0px;
      }
    }
  }
}
