@import "../../assets/scss/Variables.scss";

.alectify-drawer {
  .ant-drawer-header {
    background-color: $alectify-primary-blue;

    .ant-drawer-title {
      color: #fff;
    }

    .ant-drawer-close {
      color: #fff;
    }
  }
}
