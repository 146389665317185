@import "../../../../assets/scss/Variables.scss";

.work-order-status-container {
  padding-right: 5px;
  height: calc(100vh - 172px);
  overflow: auto;

  .round-tag {
    padding: 3px 5px !important;
    border-radius: 5px;
    width: 100%;
  }

  .status-asset-section {
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #e6eefe;

    .alectify-pm-basic-info-image-card {
      overflow: hidden;
      height: 100%;
      margin-right: 10px;

      .alectify-pm-basic-info-image {
        height: 160px;
        border-radius: 10px;
        border: 1px solid #d1d1d1;
      }
      .alectify-pm-basic-info-image-text {
        color: #0954f1;
      }

      .ant-upload-select {
        width: 100%;
      }
      .edit-profile-text {
        display: flex;
        justify-content: center;
        color: #0954f1;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .status-work-order-detail-section {
    padding: 10px;
    overflow: hidden;
    .pm-detail-title {
      align-items: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
      margin-bottom: 5px;

      .action-button {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
      }
    }
  }

  .date-chip {
    background: #ffebeb;
    border-radius: 5px;
    color: #f94c4c;
    border: 1px solid #f94c4c;
    padding: 5px;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.13px;
  }

  .assignee-container {
    width: 340px;
    .alectify-status-card {
      box-shadow: none !important;
    }
  }

  .alectify-pm-info-detail-key {
    font-size: 14px !important;
    color: #8f8c8c !important;
    font-weight: 500 !important;
  }

  .alectify-pm-info-detail-value {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      font-size: 13px !important;
    }
  }

  .space-container {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    .critical-chip {
      width: 100%;
    }

    .alectify-chip {
      margin-left: 0;
      box-shadow: none;
    }
  }

  .site-asset-container {
    display: flex;
    width: fit-content;
    max-width: 100%;
    padding: 3px 5px;
    background: #c7daebbd;
    border-radius: 6px;
    font-size: 13px;
    border: 1px solid #9abbda;
  }

  .alectify-pm-info-detail-key-site {
    white-space: wrap;
  }
  .key-text {
    margin-right: 5px;
    font-size: 14px !important;
    font-weight: 500 !important;
    width: fit-content;
    white-space: nowrap;
  }
  .text-custom {
    font-size: 12px;
  }

  .site-asset-container content {
    text-align: left;
  }
  .review-text {
    .ant-typography {
      font-size: 14px;
    }
  }

  .description-body {
    width: 100%;
    margin-top: 10px;
    .alectify-description-heading {
      font-weight: normal;
      font-size: 14px;
    }
    .alectify-description-view-body {
      background-color: #fff;
    }
  }

  .pm-detail-info-steps {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
    .ant-steps {
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: #3e3e3e;
          font-size: 14px;
        }
        .ant-steps-item-description {
          color: #3e3e3e;
          font-family: Rubik;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.1px;
          .completed-text {
            color: #2e920b;
            font-family: Rubik;
            font-size: 12px;
            font-style: normal;
            font-weight: bold;
            line-height: 150%;
            letter-spacing: 0.1px;
          }
          .status-description-step-date-text {
            color: #3e3e3e;
            font-family: Rubik;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            letter-spacing: 0.1px;
          }
        }
      }
    }
  }
  .pm-detail-cm-pm-chip {
    border-radius: 5px;
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .sub-info {
    font-size: 0.85em;
    // color: black;
    // font-style: italic;
    margin-top: 5px;
    display: block;
    .recurring-text {
      color: #2e920b;
    }
    .future-text {
      color: #fcad2d;
    }
  }
  .future-info {
    font-size: 0.85em;
    color: black;
    letter-spacing: 1px;
    margin-top: 5px;
    display: block;
    .future-text {
      color: #b7693a;
    }
  }

  .border-title {
    border-top: 1px solid rgb(224, 222, 222);
    padding: 4px 10px 0px 8px;
  }

  .pm-detail-title-text {
    overflow: hidden;
    color: #141b34;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
  }

  .ant-collapse-header-text {
    font-weight: 600;
  }
  .ant-collapse-item {
    background-color: $alectify-white;
  }
  .ant-collapse-header {
    align-items: center !important;
    padding: 12px 8px !important;
  }

  .ant-collapse-content {
    border-top: 1px solid $alectify-border-color;
  }

  .ant-collapse {
    border-top: 1px solid $alectify-border-color;
    border-right: 1px solid $alectify-border-color;
    border-left: 1px solid $alectify-border-color;
  }
  .ant-collapse-item {
    border-radius: 10px !important;
    border-bottom: 1px solid $alectify-border-color;
  }

  .work_id-container {
    font-size: 0.85em;
    color: $alectify-text-color;
  }

  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #e9ffe6 !important;
    border-color: #2e920b !important;

    svg {
      fill: #2e920b !important;
    }
  }

  .ant-steps-item-error .ant-steps-item-icon {
    background-color: #ffcfcf !important;
    border-color: #ff4d4f !important;
    svg {
      fill: #ff4d4f !important;
    }
  }

  .infinite-scroll-component {
    overflow-x: hidden !important;
  }
}
.alectify-status-card {
  box-shadow: none;
}

.history-panel {
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .comment-section {
    .editor-body {
      background-color: #e6eefe9c;
      border-bottom: 1px solid transparent;

      box-shadow:
        rgba(99, 154, 248, 0.25) 0px 1px 1px,
        rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      .ql-toolbar {
        border-radius: 0px !important;
      }
    }
  }

  .ql-snow {
    border: none;
  }
  .ql-editor {
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid $alectify-border-color;
  }
}

.alectify-error-message {
  color: #f94c4c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.13px;
}
