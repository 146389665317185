.master-content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .map-container {
    height: 36vh;
  }
}
