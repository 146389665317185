.chart-body {
  // box-shadow: 0px 0px 10px 0px #00000040;
  // border: 1px solid #d7d7d7;
  width: 100%;
  overflow: hidden;

  tspan {
    font-size: 12px;
  }
}
