.image-container {
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.overlay:hover {
  opacity: 0.7;
}

.view-image-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  .downlod-icon {
    font-size: 15px !important;
  }
  .alectify-link-button {
    font-size: 15px;
  }
}
