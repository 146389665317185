@import "../../../assets/scss/Variables.scss";

.procedure-imported-list {
  height: 860px;
}
.procedure-import-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
  border: 4px dashed #ddd;
  background-color: #f9f9f9;

  .procedure-import-main-content {
    .procedure-import-empty-icon-and-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .procedure-import-empty-text {
        color: $alectify-light-grey-empty-color;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
      }
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.import-procedure-button {
  button {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    border-radius: 10px;
    border: 1px solid #abc7ee;
    background: #4f85f9;
    height: 47px;
    color: white;
  }
}
