.alectify-sub-project-list-container {
  align-items: center;
}

.switch-sub-project-card-container {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px 15px;
  text-align: left !important;
  width: 100%;
  min-height: 100px;
  cursor: pointer;

  .footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #e8f1fd;
    padding: 10px 0px;

    .ant-tag {
      display: flex;
      align-items: center;
      gap: 6px;
      width: max-content;
      padding: 2px 8px;
    }
  }
}

.switch-sub-project-searchbar {
  input {
    height: 35px;
  }
}
