.chatbot {
  height: 100vh;
  display: flex;
  flex-direction: column;

  /* header styles*/
  .chatbot-header-container {
    background-color: #e8f1fd;
    height: 5rem;
    border-radius: 0rem 0rem 1.5625rem 1.5625rem;
    display: flex;
    padding: 10px 10px;
    align-items: center;
    justify-content: space-between;
    .chatbot-text-container {
      display: flex;
      .text-status {
        margin-left: 10px;
        display: flex;
        align-items: center;

        h3 {
          margin: 0;
          padding: 0;
          color: #3369ff;
          font-size: 1.1rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    .disable-button {
      border: 1px solid rgb(189, 185, 185);
    }
  }

  /* display_message_body_styles*/
  .chatbot-initial-message-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ffff;
    overflow-y: auto;
    padding: 1rem;

    .greeting-text {
      display: flex;
      align-items: center;
      color: #0954f1;
      font-size: 1rem;
      font-style: normal;
      margin: 5px 0 0 0 !important;
    }
    .help-text {
      p {
        color: #545454;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin: 5px 0 10px 0 !important;
        letter-spacing: 0.6px;
      }
    }
    .prompt-messages {
      display: flex;
      width: 22.4375rem;
      padding: 6px;
      margin: 0 0 10px 0;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      border-radius: 1.875rem;
      background: #f6f6f6;
    }
  }

  .chatbot-conversion-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 10px;
    p {
      margin: 0;
    }

    .user-question {
      .user-text {
        float: right;
        width: 22.4375rem;
        padding: 1.1rem;
        color: #fff;
        border-radius: 1.875rem 1.875rem 0rem 1.875rem;
        background: #0954f1;
        font-size: 0.9rem;
        overflow-wrap: break-word;
        line-height: 25px;
        margin-right: 3px;
      }
    }
    .received-message {
      display: flex;
      .assistant-text {
        float: left;
        word-break: break-all;
        margin-right: 3px;
        margin-bottom: 5px;
        line-height: 25px;
        color: #333;
        background-color: #e1e1e1;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        border-radius: 0rem 1.875rem 1.875rem 1.875rem;
        font-size: 0.9rem;
        padding: 1.1rem;
      }
    }
    .read-page-container {
      border-radius: 1.875rem;
      border: 1px solid #e5e9eb;
      background: #fff;
      width: 25rem;
      float: right;
      padding: 10px;
      margin-right: 3px;
      margin-bottom: 5px;

      .pdf-container {
        display: flex;
        border-radius: 0.8125rem;
        background: #e8f1fd;
        padding: 10px;
        align-items: center;
        cursor: pointer;
        p {
          color: #141b35d3;
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0;
          font-size: 13px;
        }
      }
      h3 {
        margin: 0;
        padding-left: 10px;
        padding-bottom: 10px;
        color: #545454;
        font-size: 0.8rem;
        font-weight: 600;
      }
      span {
        color: #0954f1;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
    .display-none {
      display: none;
    }
  }
  /* reply_input_body_styles */
  .chatbot-reply-container {
    display: flex;
    align-items: center;
    max-height: 3.5rem;
    position: sticky;
    margin: 10px 10px 20px 10px;
    padding: 10px;
    border-radius: 62.5rem;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.19);
    bottom: 0;
    .reply-body {
      height: 100%;
      width: 90%;
    }
    .ant-form-item {
      margin: 0 !important;
    }
  }
  .ant-input {
    border: none !important;
    box-shadow: none !important;
  }

  .disable-input {
    background: rgba(0, 0, 0, 0.04);
  }
  .ant-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
  }
  .ant-input[disabled] {
    background: transparent !important;
  }
}
