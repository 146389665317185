@import "../../../../assets/scss/Variables.scss";

.asset-timeline-wrapper {
  .timeline-box {
    margin-bottom: 20px;
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 2px 0px 8px 10px !important;
  }
  padding: 10px 14px 0px 0px;
  background-color: #f6f6f6;
  background: #fff;
  box-shadow: 0px 13px 25px 0px rgba(0, 0, 0, 0.15);
  .timeline-date-search {
    position: sticky;
    top: 94px;
    z-index: 9;
    background-color: white;

    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
  }

  .assets-timeline {
    .ant-timeline-item-label {
      width: calc(20% - 12px) !important;
    }

    .ant-timeline-item-tail {
      inset-inline-start: 20% !important;
      border-inline-start: 1px solid $alectify-primary-blue;
    }

    .ant-timeline-item-head {
      inset-inline-start: 20% !important;
      margin-top: 12px;
    }

    .ant-timeline-item-content {
      width: calc(80% - 22px) !important;
      inset-inline-start: calc(20% - 4px) !important;
    }
    .timeline-arrow-icon {
      font-size: 15px !important;
    }
  }
  .timeline-skeleton-loader {
    padding: 5px 10vw 0px 15vw;
  }
}

.timeline-collapse {
  margin-bottom: 20px;
  border: 0 !important;
  .ant-collapse-item-active {
    .ant-collapse-header {
      padding: 6px 10px !important;
      align-items: center !important;
      background-color: $alectify-secondary-blue;
      border-radius: 10px 0px 0px 10px !important;
      box-shadow: none;
    }
  }
  .ant-collapse-header {
    padding: 6px 10px !important;
    align-items: center !important;
    background-color: $alectify-white;
    border-radius: 15px 15px 0px 0px;
    background: #f4f5f7;
    margin-top: 10px;
    h4 {
      margin: 0;
    }

    div.desc {
      margin: 0;
      color: #141b35;
    }

    .at-item-title {
      color: $alectify-primary-blue;
      font-weight: 600;
    }
  }
  .ant-collapse-item {
    border-bottom: 0;
  }
  .ant-collapse-content {
    border: 0 !important;
  }
  .ant-collapse-content-box {
    max-height: 500px;
    border: 1px solid rgb(223, 220, 220);
    border-radius: 0 0 20px 20px;
  }
}

@mixin hoverAndActiveStyles {
  color: $alectify-primary-blue;

  .ant-tabs-tab-btn {
    color: $alectify-primary-blue;
  }

  svg {
    path[fill="#141B34"] {
      fill: $alectify-primary-blue;
    }

    path[stroke="#141B34"] {
      stroke: $alectify-primary-blue;
    }

    rect[stroke="#141B34"] {
      stroke: $alectify-primary-blue;
    }
  }
}

.timeline-tabs-container {
  height: calc(100vh - 100px);
  .ant-tabs-nav {
    margin-bottom: 8px;
  }
  .ant-tabs-tab {
    font-size: 14px;
    padding: 10px 15px;

    svg {
      vertical-align: middle;
      margin-right: 0px;
      height: 18px;
    }

    & + .ant-tabs-tab {
      margin-left: 0px;
    }

    &:hover {
      @include hoverAndActiveStyles;
    }

    &.ant-tabs-tab-active {
      @include hoverAndActiveStyles;
    }
  }

  .ant-tabs-content-holder {
    padding: 5px 5px 22px 5px;
  }
}
