.loading {
  position: relative;
  color: #333;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 25px;
  font-style: normal;

  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #000;
    margin: 0 3px;
    animation: blink 1.5s infinite;
  }

  .dot:nth-child(2) {
    animation-delay: 0.5s;
  }

  .dot:nth-child(3) {
    animation-delay: 1s;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
