// Black Colors
$alectify-text-color: #141b34; //Dark knight
$alectify-secondary-text: #968378;

//Grey Colors
// $alectify-secondary-color: #545454; // Dark Grey
$text-color-light-grey: #84919a; // Light Grey
$alectify-disabled-grey-color: #b9bbc7; // Muted Grey
$alectify-light-grey-empty-color: #bababa; // Baby Talk Grey
$alectify-gray-color: #eef0f2; // Paper White
$alectify-secondary-bg-color: #f6f8fc; // Light Background Grey
$alectify-white: #ffff; // White

//Red Colors
$alectify-red: #ff2323;
$alectify-warning-bg-color: #fff5f5; // Soft Pinkish White
$alectify-green: #005f20;

//Blue Colors
// $alectify-blue: #070bce;
$alectify-light-blue-bg: #f5f8fc;

$alectify-primary-blue: #0954f1;
$alectify-secondary-blue: #e8f1fd;
$alectify-tertiary-blue: #f5f8ff;

$alectify-placeholder-color: #bacffd;
$alectify-border-color: #dae5f3; //space wolf
$alectify-lightgray: #f5f7fa;

$alectify-font-size-15: 15px;
$alectify-regular:
  "alectify-regular",
  Roboto,
  Helvetica Neue,
  sans-serif;
