@import "../../../../assets/scss/Variables.scss";

.master-project-detail-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    h1 {
      font-size: 19px;
      color: #141b35;
      margin-top: 4px;
    }
  }

  .body {
    div.ant-typography {
      color: $text-color-light-grey;
      font-size: 14px;
      margin-bottom: 1rem;
    }

    .details {
      font-size: 14px;

      .ant-tag {
        font-size: 14px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .organization,
      .location {
        display: inline-block;
        vertical-align: middle;
        color: $text-color-light-grey;

        img {
          max-height: 28px;
        }
      }

      .location {
        margin-left: 10px;
      }
    }
  }
}
