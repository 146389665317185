.asset-detail-header-main {
  border-radius: 9px;
  background: #f6f8fc;
  display: flex;
  padding: 10px 15px;
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 220px;

  .image-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    padding: 10px;
    border-radius: 12px;
  }

  .edit-profile-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0954f1;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
    margin-top: 5px;
  }

  .box-container {
    padding: 5px;
    border-radius: 11px;
    border: 1px solid #dae5f3;
    background: #fff;
    width: 100%;
    height: 148px;

    p {
      color: #3e3e3e;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .asset-detail-box-description {
    height: 148px;
    padding: 5px;
    border-radius: 11px;
    border: 1px solid #dae5f3;
    background: #fff;

    p {
      color: #3e3e3e;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.13px;
    }
  }

  .asset-sub-heading {
    color: #233043;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.asset-detail-main {
  .asset-title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
  }
  .asset-heading {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.border-right {
  border-right: 1px solid #dae5f3;
}

.border-left {
  border-left: 1px solid #dae5f3;
}

.border-top {
  border-top: 1px solid #dae5f3;
}

.border-bottom {
  border-bottom: 1px solid #dae5f3;
}

.description-box {
  overflow: hidden;
  color: #3e3e3e;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.13px;
}

.paddingBox {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  p {
    font-size: 13px;
    margin-bottom: 4px;
  }
}

.number-box {
  color: #000;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}

.step-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  color: #0954f1;
  background-color: #e8f1fd;
  font-size: 14px;
}

.asset-detail-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.asset-detail-main {
  .breadcrumbs-sticky {
    position: sticky;
    text-align: center;
    display: flex;
    align-items: center;
    height: 30px;
    top: 0px;
    z-index: 9;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 10px;
  }
}

.asset-detail-main .header-sticky {
  position: sticky;
  top: 29px;
  background: #fff;
  height: 30px;
  z-index: 9;
  padding-bottom: 10px;
}

.asset-detail-main .detail {
  height: 220px;
  z-index: 8;
}

.asset-detail-main .timeline-tabs-container .ant-tabs-nav {
  position: sticky;
  top: 55px;
  z-index: 10;
  background-color: #fff;
}
