@import "../../../assets/scss/Variables.scss";

.alectify-document-list {
  .main-document-action {
    margin: 0 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow: auto;
    list-style-type: none;
    max-height: calc(100vh - 520px);
    padding: 10px;
    li {
      display: flex;
      padding: 10px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 7px;
      background: #fff;
      cursor: pointer;
      transition: all linear ease-in-out;
      margin-bottom: 7px;
      svg {
        vertical-align: middle;
      }

      &:hover {
        background-color: $alectify-secondary-blue;

        svg {
          rect[fill="#E8F1FD"] {
            fill: $alectify-primary-blue !important;
          }

          path[fill="#E8F1FD"] {
            fill: $alectify-primary-blue !important;
          }

          path[fill="#0954F1"] {
            fill: #fff !important;
          }

          path[stroke="#0954F1"] {
            stroke: #fff !important;
          }
        }
      }
    }
  }
}

.alectify-add-document-card {
  .ant-card-head {
    .ant-card-head-wrapper {
      display: flex;
      align-items: flex-start;
    }
  }
  .ant-card-body {
    padding: 10px 0;
  }
  .browse-document-description {
    color: green;
  }
  border-radius: 7px;
  background: #f6f8fc;

  h5 {
    font-size: 14px !important;
    color: #2b2929de;
    font-weight: 600;
  }
}

.selected-documents-list {
  max-height: 250px;
  overflow-y: auto;
  width: 100px;
}

.alectify-documen-link-button {
  &.ant-btn-link {
    padding: 0px;
    span {
      text-decoration: underline;
    }
  }
}

.document-file-upload-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .ant-space-item {
    a {
      text-decoration: underline;
      color: #0954f1;
      cursor: pointer;
    }
  }
}

.upload-container {
  .ant-upload-select {
    width: 100%;
  }
  display: flex;
  align-items: center;
  width: 100%;
  .upload-container-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 14px;
    }
  }
}

.browse-container {
  width: 100%;

  .ant-upload-select {
    width: 100%;
  }
  .ant-btn {
    width: 100%;
    height: 33px;
  }
}
