.alectify-datepicker {
  height: 50px;
  .ant-picker-input input::placeholder {
    color: #787878;
  }
}
.alectify-datepicker-small {
  height: 45px;
}
.full-width-picker {
  width: 100%;
  .ant-picker-header {
    display: none;
    width: 100%;
  }
  .ant-picker-body {
    background-color: #f8f9fb;
    width: 100%;
  }
}
