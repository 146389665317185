.card-body-row {
  .ant-card-body {
    padding: 0 !important;
  }

  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}
.custom-card-title {
  color: #252525;
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;

  .count {
    color: #252525;
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
  }
}

.card-body-col {
  // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  border-radius: 25px;
  background: #e3ecff;

  .ant-card-head {
    border-bottom: 0;
  }

  .ant-card-head-title {
    border-bottom: 1px solid #a5bdf1;
    padding-bottom: 7px;
    text-align: center;
  }

  .ant-card-body {
    padding: 0 0 20px 0 !important;
  }
}
