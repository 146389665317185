.project-basic-info-container {
  margin-top: 20px;
  h3 {
    margin-top: 5px !important;
  }
  .ant-form-item-label {
    label {
      width: 100%;
    }
  }
}
