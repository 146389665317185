.alectify-checklist-dnd-cards {
  .camera-icon {
    margin-top: 10px;
  }
  .image-viewer {
    .remove-text {
      margin-top: -5px;
      font-size: 12px;
    }
  }
  .alectify-checklist-jop-description-input {
    .ant-input {
      height: 60px !important;
    }
  }
}

.alectify-checklist-card-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  .alectify-checklist-dnd-cards {
    flex: 1;
  }
}
