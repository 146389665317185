.global-search-results-container {
  background-color: #ffffff;
  border-radius: 10px;
  max-height: 50rem;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: center;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.global-search-card-container {
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e9eb;

  .global-search-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    padding-top: 10px;
    border-radius: 10px;
    p {
      margin: 0 !important;
    }
    background: transparent;
  }

  .faded-text {
    color: #777676;
    margin-left: 5px;
    margin-right: 5px;
  }

  .dark-text {
    color: #141b35;
    letter-spacing: -0.28px;
    font-size: 14px;
    font-weight: 600;
  }

  .text-color-font {
    color: #141b35;
    font-weight: 500;
    font-size: 14px;
  }
  .search-parent-asset-link {
    color: #1a9d75 !important;
  }
  .search-result-master-subProject-detail {
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
  }

  &:hover {
    background-color: #e8f1fd;
  }
}

.empty-container {
  height: 100%;
}

.color-dot {
  height: 8px;
  width: 8px;
  margin-right: 5px;
  border-radius: 50%;
}

.goto-page-icon {
  &:hover {
    color: #0954f1;
  }
}

.page-container {
  height: 4rem;
  margin-top: -30px;
  padding-top: 10px;
  color: grey;
}
