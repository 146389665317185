.heading-review-confirm {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #f1f1f1;
  margin-top: 10px;
  margin-bottom: 10px;
  span {
    font-size: 16px;
    color: #1f2d56;
    font-weight: 500;
  }
}
.heading-review-confirm-danger {
  @extend .heading-review-confirm;
  span {
    color: red;
  }
}

// .card-container-review {
// padding: 10px 0px;
// border-radius: 4px;
// border: 1px solid #dae5f3;
// height: 33rem;
// overflow: auto;
// }
.confirmation-container {
  text-align: center;
  .title-text-confirmation-common {
    display: block;
    text-align: center;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.14px;
  }

  .title-text-confirmation {
    color: #000;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .sub-title-text-confirmation {
    color: #645d5d;
    margin-bottom: 20px;
  }
}

.card-container-review {
  ul {
    margin: 0;
    padding: 0px 0px 0px 15px;
    li {
      color: var(--alectify-text-light-black);
    }
  }

  .comment-text {
    color: var(--alectify-text-light-black);
  }
}
