.ai-page-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  height: 100%;
  .title-left {
    color: #000103;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 8px;
    align-items: center;
    margin-bottom: 10px;
  }

  .ai-col-container {
    border-radius: 25px;
    border-right: 1px solid #e5e9eb;
    background: #f6f8fc;
    height: 100%;
    padding: 20px 15px 15px 15px;
  }
}

.ant-spin-container {
  height: 100%;
}
