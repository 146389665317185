@import "../../../../assets/scss/Variables.scss";

.alectify-work-order-details-container {
  margin: -10px !important;
  transition: all 0.3s ease-in-out;
  padding-right: 10px;

  .alectify-work-order-task-details-section {
    border: 1px solid $alectify-secondary-blue;
    padding: 15px 15px 0px 15px;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .alectify-work-order-task-documents-details-section {
    padding-top: 10px;
    transition: all 0.3s ease-in-out;
  }
  .alectify-work-order-task-comments-details-section {
    transition: all 0.3s ease-in-out;

    .comments-header-title {
      background-color: #e6eefe;
      padding: 10px 15px 10px 0px;
      text-align: center;
      border-top-left-radius: 10px;
      border-bottom: 1px solid #d8d0d0;
    }
    border-left: 1px solid #e0dede;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    padding: 0px;
    min-height: calc(100vh - 81px);
    border-radius: 10px;

    .reply-body {
      padding-left: 10px;
      height: calc(100vh - 446px);
    }
  }
  .alectify-work-order-task-edit-btn > button {
    width: 100%;
  }

  .edit-box {
    margin-top: 20px;
  }
  .reply-body {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .pm-detail-title {
    margin: 0px 0px 10px 0px;
    padding-top: 10px;
    .pm-detail-cm-pm-chip {
      display: flex;
      border-radius: 4px;
      border: 0.3px solid var(--Colors-Primary-Color, #0954f1);
      background: var(--Colors-sample-hover, #e8f1fd);
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.27);
      color: var(--Colors-Primary-Color, #0954f1);
      font-size: 13px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      padding: 5px;
      white-space: nowrap;
      flex-grow: 1;
    }
    .pm-detail-title-text {
      overflow: hidden;
      color: #141b34;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 22px;
    }
  }
}

.alectify-detail-page-action-buttons-container {
  margin-right: 10px;
}

.messaging-center-drawer {
  .ant-drawer-body {
    overflow: hidden;
    padding: 0px !important;
  }
}

.history-container {
  border-top: 1px solid #e5e9eb;
  padding: 7px 10px 8px 4px;

  p {
    color: #141b35;
    display: flex;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: -0.01875rem;
    line-height: 1.125rem;
    margin: 0;

    .user-name-container {
      font-weight: 900;
      margin-right: 3px;
      white-space: nowrap;
    }
  }

  .date-time-container {
    color: #898989;
    font-size: 0.7rem;
    font-weight: 400;
    margin-left: 10px;
    white-space: nowrap;
    letter-spacing: -0.01625rem;
    line-height: 1.125rem;
    margin-right: 3px;
    align-items: center;
  }
}

.system-message-container {
  display: flex;
  font-size: 0.8rem;

  span {
    margin-right: 3px;
  }
}

.avatar-container-history {
  display: flex;
  align-items: center;
  gap: 10px;
}

.first-row-history {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second-row-history {
  margin-left: 40px;
}

.transcript-content {
  max-height: 0;
  overflow: hidden;
  font-size: 12px;
  font-style: italic;
  opacity: 0;
  transition:
    max-height 0.4s ease,
    opacity 0.4s ease;

  &.visible {
    max-height: 300px;
    opacity: 1;
    overflow: auto;
  }
}

.audio-player-main {
  display: flex;
  align-items: center;
}

.attachment-audio-container {
  width: 34rem;
}

.show-transcript-text {
  font-size: 12px;
  margin-top: 28px;
  margin-left: 5px;
  color: $text-color-light-grey;
}
