@import "./Variables.scss";

:root {
  /* Black Colors */
  --alectify-text-color: #141b34; /* Dark Knight */
  --alectify-secondary-text: #968378;
  --alectify-text-light-black: #3d3d3c;
  --alectify-text-light-black2: rgb(80, 80, 79);

  /* Grey Colors */
  --alectify-secondary-color: #545454; /* Dark Grey */
  --text-color-light-grey: #84919a; /* Light Grey */
  --alectify-disabled-grey-color: #b9bbc7; /* Muted Grey */
  --alectify-light-grey-empty-color: #bababa; /* Baby Talk Grey */
  --alectify-gray-color: #eef0f2; /* Paper White */
  --alectify-secondary-bg-color: #f6f8fc; /* Light Background Grey */
  --alectify-white: #ffff; /* White */

  /* Red Colors */
  --alectify-red: #ff2323;
  --alectify-warning-bg-color: #fff5f5; /* Soft Pinkish White */
  --alectify-green: #005f20;

  /* Blue Colors */
  --alectify-light-blue-bg: #f5f8fc;
  --alectify-primary-blue: #0954f1;
  --alectify-secondary-blue: #e8f1fd;
  --alectify-tertiary-blue: #f5f8ff;

  /* Additional Colors */
  --alectify-placeholder-color: #bacffd;
  --alectify-border-color: #dae5f3; /* Space Wolf */
  --alectify-lightgray: #f5f7fa;

  /* Font Size */
  --alectify-font-size-15: 15px;

  /* Font Family */
  --alectify-regular: "alectify-regular", Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: $alectify-regular;
  src: url("../fonts/Rubik-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

*,
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
table,
li,
label,
span,
legend,
button,
.ant-typography {
  font-family: "Rubik", sans-serif !important;

  // color: #141B34;
  // font-family: $alectify-regular;
}

// by default setting for rem
html {
  font-size: 16px;
}

// right spacing styles
.mr-5 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}
// bottom spacing styles
.mb-5 {
  margin-bottom: 5px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-5 {
  margin-left: 5px;
}

.mb-8 {
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

// top spacing styles
.mt-0 {
  margin-top: 0px !important;
}

.mt-3 {
  margin-top: 3px;
}
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.m-0 {
  margin: 0px !important;
}

.ml-20 {
  margin-left: 20px;
}

.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-30 {
  margin-left: 30px;
}

.ml-3 {
  margin-left: 3px;
}
.ml-5 {
  margin-left: 5px;
}
.p-0 {
  padding: 0px !important;
}
.p-3 {
  padding: 3px;
}

.p-10 {
  padding: 10px;
}

.p-5 {
  padding: 5px;
}

.pR-10 {
  padding-right: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pt-10 {
  padding-top: 10px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}
.icon-size-15 {
  font-size: 15px;
}

.overflow-auto {
  overflow: auto;
}

.dark-grey {
  color: #636466;
}
.light-blue-button {
  padding: 3px 13px;
  color: $alectify-primary-blue;
  background-color: $alectify-secondary-blue;
  &:hover {
    path {
      fill: #fff;
    }
  }
}

.font-size-15 {
  font-size: $alectify-font-size-15;
}

.font-size-17 {
  font-size: 17px;
}

.dark-blue-button {
  background-color: $alectify-primary-blue;
  color: #fff;
}

.icon-font-size-18 {
  font-size: 18px;
}

.sea-green-background {
  background-color: #ebf9ff !important;
  td {
    border-bottom: 1px solid #ade6ff !important;
  }
}

.white-background {
  background-color: #fff !important;
}
.icon-font-size-16 {
  font-size: 16px;
  vertical-align: middle;
}
.icon-font-size-20 {
  font-size: 5rem;
  vertical-align: middle;
  color: $text-color-light-grey;
}

.custom-class {
  margin-top: 5px;
}

.rbc-show-more {
  margin-top: auto;
  padding-left: 10px;
}

.font-size-14 {
  font-size: 14px;
}

.date-fonts {
  color: #898989;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: -0.01625rem;
  line-height: 1.125rem;
  padding-right: 10px;
}
.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.m-5 {
  margin: 5px;
}
.m-2 {
  margin: 2px;
}
.mt-1 {
  margin-top: 1px;
}

.mb-1 {
  margin-bottom: 1px;
}
.mt-2 {
  margin-top: 2px;
}

.mt-3 {
  margin-top: px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-3 {
  margin-bottom: 3px;
}
.height-100 {
  height: 100%;
}
.caret-transparent {
  caret-color: transparent;
}

.disable-cell {
  pointer-events: none;
  filter: grayscale(100%);
  opacity: 0.5;
}

.disable-cell .sync-active {
  filter: grayscale(0%) !important;
  opacity: 1 !important;
}

.disable-row {
  pointer-events: none;
  filter: grayscale(100%);
  opacity: 0.5;

  td {
    color: grey;
  }
  .ant-table-cell-row-hover {
    background: transparent !important;
  }
}

.disable-row-background {
  background-color: #fff !important;
}

.sync-icon {
  font-size: 16px;
  color: $alectify-primary-blue;
  cursor: pointer;
}

.editor-render-only-content {
  border: none !important;
}
.disable-row-allow-actions {
  filter: grayscale(100%);
  opacity: 0.5;

  td {
    color: grey;
  }
  .ant-table-cell-row-hover {
    background: transparent !important;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.black-text {
  color: #000;
}

.font-size-12 {
  font-size: 12px;
}
.d-flex {
  display: flex;
}

.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.background-color-light-grey {
  background-color: #f6f6f6;
}
.alectify-primary-colorcolor-grey {
  color: $alectify-text-color !important;
}

.text-underline {
  text-decoration: underline;
}
.color-light-grey {
  color: $alectify-light-grey-empty-color;
}

.width-100 {
  width: 100%;
}
.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination {
  .ant-pagination-item {
    &.ant-pagination-item-active {
      color: $alectify-primary-blue;
      background-color: $alectify-secondary-blue;
    }
  }
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}
.text-white-space-normal {
  white-space: normal;
}
.text-white-space-nowrap {
  white-space: nowrap;
}
.border-blue {
  border: 1px solid #0954f1;
}

.border-light-grey {
  border: 1px solid #f0f0f0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.antd-alectify-vertical-tabs-menu {
  .ant-tabs-tab-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .tab-count {
      background-color: #f0f2f8 !important;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $alectify-primary-blue;
    }
  }
}

.full-height {
  height: 100%;

  & > .ant-spin-container {
    height: 100%;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  visibility: visible;
}

.alectify-drawer-task-comments {
  .ant-drawer-body {
    flex: 1;
    padding: 10px 10px 10px 10px;
    overflow: hidden;
  }
}

.ant-breadcrumb {
  font-size: 14px;
  ol {
    li {
      &:last-child {
        color: #545454;
        font-family: Rubik;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

span {
  &.ant-typography {
    font-size: 14px;
  }
}

.ant-tag {
  font-size: 0.7rem;
}

.current-user-btn {
  font-size: 16px;
  border: none;
  color: alectify-text-color;
}

.ant-tooltip-inner {
  font-size: 0.7rem;
}

.search-address-wrapper {
  position: relative;
  width: 100%;
  align-items: center;

  input {
    border: 1px solid #d9d9d9;
    min-height: 36px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 25px;
    padding: 2px 10px;

    &:focus {
      outline: 1px solid rgb(101, 143, 249);
    }
  }
}
.grey-card {
  background-color: #fcfcfc;

  & + .grey-card {
    margin-top: 15px;
  }

  .title {
    font-size: 1rem;
    margin: 0;
    padding: 10px 10px;
    border-bottom: 1px solid #d6e5f9;
  }

  .body {
    padding: 10px;

    .asset-image {
      width: 70%;
    }

    .full-width {
      & + .full-width {
        margin-top: 5px;
      }
    }

    .ant-space-item:has(.label) {
      width: 100px;
      flex-shrink: 0;
    }

    .label {
      color: $text-color-light-grey;
      font-size: 14px;
      min-width: 10px;
    }

    .value {
      color: #000;
      font-size: 14px;
      min-width: 10px;
    }
  }
}

.full-width {
  width: 100%;
}

.no-border {
  border-bottom: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

/* Scroll style global */
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Scroll style global */

.documents-upload-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 0.5px solid #939697b1;
  width: 100%;
  padding-top: 10px;
  margin-top: 10px;
  .doc-upload-bottom {
    display: flex;
    gap: 12px;
  }
}

.ant-btn-text.ant-btn-dangerous {
  background-color: transparent;
}

.red-text {
  color: rgb(249, 76, 76) !important;
}

.green-text {
  color: #238443;
}

.form-label-extra {
  position: absolute;
  right: 0;
}

.masterproject-option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.masterproject-option-color {
  font-size: 20px;
  margin-right: 5px;
}

.primary-text-color {
  color: $alectify-primary-blue;
}

.alectify-success-modal {
  .ant-modal-content {
    border-radius: 13px;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alectify-grey-text {
  color: #949494;
  font-family: Rubik;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.alectify-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.alectify-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.alectify-warning-box {
  background-color: $alectify-warning-bg-color;
  border-radius: 10px;
  padding: 10px;
}

.alectify-pdf-viewer-container {
  .ant-modal-content .ant-modal-body {
    padding: 4px 6px;
  }
}

.alectify-timeline-date-tag {
  color: #555 !important;
  border: 1px solid #d9d9d9 !important;
}

.alectify-select-add-dropdown {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.Alectify-siderbar-menu-icon {
  width: 24px;
  height: 25px;
}
.border-none {
  border: none !important;
}

.width-asset-type-text-control {
  width: 12.4rem;
}

.maintainance-icon-wrapper {
  width: 35px;
  height: 35px;
  background: #ffe8d6;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-to-link-options {
  color: #0954f1 !important;
  &:hover {
    font-weight: 700 !important;
  }
}

.ant-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.black-text-with-active-hover {
  color: black !important;
  &:hover {
    color: #0954f1 !important;
  }
}

.pointer-events-none {
  color: black;
  pointer-events: none;
}

.cm-pm-chip {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 0.3px solid #0954f1;
  background: #e8f1fd;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.27);
  margin-left: 5px;
  span {
    color: #0954f1;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
}

.alectify-card-description {
  color: #545454;
  font-weight: 400;
}

.alectify-sticky-buttons-container {
  position: sticky;
  bottom: -30px;
  width: 100%;
  padding: 5px 0px;
  background-color: white;
  z-index: 8;
}

.alectify-avatar-clickable-border {
  border: 1px solid #0954f1;
  box-shadow: 0px 0px 5px 0px #8b8b8b;
}

.alectify-action-icon {
  color: #686262;
  font-size: 30px;
}

.alectify-teams-users-radio {
  border: 1px solid #dae5f3;
  padding: 5px;
  border-radius: 8px;
  width: 100%;
  margin: 0;
}

.alectify-pm-create-edit-form .ant-select-selection-item {
  height: 30px;
}

@media screen and (max-height: 768px) {
  .Alectify-siderbar-menu-icon {
    width: 20px;
    height: 21px;
  }
}

@media screen and (max-height: 664px) {
  .Alectify-siderbar-menu-icon {
    width: 18px;
    height: 19px;
  }
}

.grey-bg-wrapper {
  background: #f6f8fc;
  border-radius: 10px;
  padding: 15px;
}

.order-calculated-price {
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 16px;
    margin: 0 !important;

    &:first-child {
      color: #1f2d56;
    }
    &:last-child {
      color: #0e945a;
    }
  }
}

/* smaller screens or 125% */
@media screen and (max-height: 900px) {
  .ant-tabs-tab-btn {
    font-size: 12px !important;
  }
  .ant-btn {
    font-size: 12px !important;
  }
  .alectify-table {
    .ant-typography {
      font-size: 12px !important;
    }
    .ant-table-column-title {
      font-size: 12px !important;
    }
    .ant-table-cell {
      font-size: 12px !important;
    }
    .creator-name-date {
      font-size: 12px !important;
    }
  }
}

.ai-draggable-container {
  z-index: 9;
  &:hover {
    box-shadow:
      rgba(175, 24, 198, 0.35) 0px 54px 55px,
      rgba(175, 24, 198, 0.35) 0px -12px 30px,
      rgba(175, 24, 198, 0.35) 0px 4px 6px,
      rgba(175, 24, 198, 0.35) 0px 12px 13px,
      rgba(175, 24, 198, 0.35) 0px -3px 5px;
  }
}
.pm-detail-cm-pm-chip {
  border-radius: 4px;
  border: 0.3px solid #0954f1;
  background: #e8f1fd;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.27);
  color: #0954f1;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  padding: 5px;
  white-space: nowrap;
  width: fit-content;
}

.mt-40 {
  margin-top: 40px;
}
.alectify-key-value {
  color: $alectify-text-color;
  font-weight: bold;
}
.disabled-text {
  color: rgba(0, 0, 0, 0.25);
}
/* Mixins */
@mixin margin($position, $value) {
  margin-#{$position}: $value;
}

@mixin generate-margin-classes($positions, $min, $max) {
  @each $position in $positions {
    @for $i from $min through $max {
      .m-#{$position}-#{$i} {
        @include margin($position, #{$i}px);
      }
    }
  }
}
// Generate margin classes for positions (top, right, bottom, left) and values from 0px to 100px
$positions: ("t", "r", "b", "l");
@include generate-margin-classes($positions, 0, 100);
