@import "../../../assets/scss/Variables.scss";

.alectify-bordered-row-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grey-strokes-outlines, #dae5f3);
  padding: 5px 10px;

  h5 {
    color: $text-color-light-grey;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .value {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    flex-shrink: 1;
  }

  & + .alectify-bordered-row-text {
    // margin-top: 10px;
    border-top: 0;
  }
}

.alectify-bordered-row-text-title {
  font-size: 14px !important;
}
