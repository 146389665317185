// Header styles
.header-title {
  color: #141b35;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.01rem;
  padding: 10px 10px 16px 8px;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  .header-options {
    display: flex;
    justify-content: space-between;
    .header-mark-read-text {
      display: flex;
      align-items: center;
      color: #141b35;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        color: #0954f1;
        .double-check-icon path {
          fill: #0954f1;
        }
      }

      .double-check-icon {
        margin-right: 3px;
        path {
          fill: grey;
        }
      }
    }

    .full-screen-container {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        border-radius: 0.25rem;
        background: #e8f1fd;
        path {
          fill: #0954f1;
        }
      }
    }
  }

  p {
    margin: 0 !important;
  }
}

// Notifications Styles

.notifications-container {
  display: flex;
  user-select: none;
  border-top: 1px solid #e5e9eb;
  padding: 7px 10px 8px 4px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  .notifications-user-logo {
    display: flex;
    align-items: center;
    .notifications-unread-dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: #0954f1;
      margin-right: 5px;
    }

    .notifications-user-icon {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      align-items: center;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .notification-internal-color {
      background-color: #7570b3;
    }
    .notification-external-color {
      background-color: #ff5656;
    }
    .notification-list-color {
      background-color: #d85f02;
    }
  }

  .notification-detail-container {
    .notification-user-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        white-space: nowrap;
        display: flex;
        color: #141b35;
        text-align: justify;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.125rem;
        letter-spacing: -0.01875rem;

        span {
          display: inline-block;
          white-space: nowrap;
          font-weight: 900;
          margin-right: 3px;
        }
      }
      .notification-date-time-text {
        color: #898989;
        font-size: 0.7rem;
        font-weight: 400;
        margin-left: 10px;
        white-space: nowrap;
      }
    }
  }

  .notification-width-full-screen {
    width: 100% !important;
  }

  .notification-width-drop-screen {
    width: 457px;
  }

  .notification-title-container {
    // border-radius: 0.3125rem;
    // border: 1px solid #e5e9eb;
    // background: #faf9f9;
    // padding: 0.5rem 0.625rem;
    // position: relative;
    color: #141b35;
    display: flex;
    justify-content: flex-start;

    .notification-text-color {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: underline;
      color: #0954f1 !important;

      &:hover {
        color: #0954f1 !important;
      }
    }

    text-overflow: ellipsis;
    text-decoration: underline;

    &:hover {
      color: #0954f1 !important;
    }
  }
  span {
    display: flex;
    align-items: center;
    color: #3a3636;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: -0.01625rem;
    margin-right: 3px;
  }
  .notification-page-workId-text {
    color: #141b35;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: -0.0175rem;
    margin-left: 5px;
  }
}

//notifications main styles
.notifications-main {
  display: flex;
  flex-direction: column;

  .notifications-body {
    flex: 1;

    &.fixed-height {
      max-height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .infinite-scroll-component {
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }
}

.notifications-page {
  .ant-form-item-label > label {
    font-size: 1rem;
  }

  .notifications-filters-rangePicker {
    padding: 11px !important;
    width: 100%;
  }

  .notification-filters-search-input {
    .ant-input-affix-wrapper {
      padding: 11px;
      border: 1px solid var(--grey-strokes-outlines, #dae5f3);
      input {
        height: 27px !important;
      }
    }
  }

  p {
    margin: 0;
  }
}

.header-title-reduce {
  font-size: 1.1rem;
}

.header-mark-read-text-reduce {
  font-size: 0.8rem !important;
}
.notification-user-detail-page {
  p {
    font-size: 1rem !important;
    span {
      max-width: 300px !important;
    }
  }
}

.notification-detail-container {
  .notification-user-detail {
    .notification-date-time-text-page {
      font-size: 0.8rem !important;
    }
  }
}

.notification-title-container {
  span {
    font-size: 0.8rem !important;
  }
}

.notifications-unread-dot-page {
  width: 0.6rem !important;
  height: 0.6rem !important;
}

.notification-icons {
  width: 55px;
  height: 55px;
}

.notification-icons-dropdown {
  width: 35px;
  height: 35px;
}

.notification-icons-reduce {
  width: 45px !important; /* Adjust width as needed */
  height: 45px !important; /* Adjust height as needed */
}

.notification-pink-dot {
  display: flex;
  width: 0.625rem;
  height: 0.625rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 6.25rem;
  background: #ffa3fb;
  margin-right: 5px;
}

.notifications-sub-detail {
  display: flex;
  padding: 0rem 0.5rem;
  align-items: center;
  border-right: 1px solid #dae5f3;
}

.notification-detail-link {
  // position: absolute;
  // right: 0;
  width: 81px;
  cursor: pointer;
  .goto-btn {
    position: absolute;
    right: 0;
    top: 2px;
    span {
      color: #fff !important;
    }
  }

  .alectify-regular-button {
    padding: 0 6px !important;
    height: 30px !important;
    font-size: 0.8rem !important;
  }
}

.see-all-notification {
  color: #555;
  font-weight: normal;
}
