.create-sub-project-body {
  height: calc(100% - 3%);
  width: 100%;
  .create-subProject-steps-body {
    .ant-card .ant-card-head {
      background: #eef0f2 !important;
      min-height: 48px !important;
    }
    .ant-card-head-title {
      font-size: 13px;
      color: #545454;
      font-weight: 600;
      text-align: center;
    }
    .ant-card-bordered {
      height: 100%;
      overflow: hidden;
    }
  }
}

.step-navigation {
  display: flex;
  align-items: center;
  background-color: #f6f8fa;
  padding: 10px;
  border-radius: 4px;
}

.step {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 50%;
}

.step .step-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  transition:
    background-color 1.3s ease-in-out,
    color 1s ease-in-out;
}

.step .text {
  font-size: 15px;
  letter-spacing: 0.4px;
  margin-left: 10px;
  transition:
    color 0.5s ease-in-out,
    font-weight 0.5s ease-in-out;
}

.text-active {
  font-weight: 600;
  color: #0954f1;
}

.step-active {
  background-color: #0954f1 !important;
  color: #ffff !important;
}

.step-not-active {
  background: #d6e2fd;
  color: #0954f1;
}

.line {
  width: 216px;
  height: 2px;
  background-color: rgb(202, 195, 195);
  margin: 0 8px;
  position: relative;
  overflow: hidden;
}

.line::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #007bff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}

.line.filled::after {
  transform: scaleX(1);
}

.create-edit-data-rounds-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .create-edit-body {
    height: calc(100vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .create-edit-data-rounds-navigation-container {
    height: 52px;
    margin-bottom: 20px;
  }

  .create-edit-data-rounds-footer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    border-top: 1px solid #ccc;
    height: 33px;
    padding-top: 20px;
  }
}

.data-rounds-basic-info-main {
  display: flex;
  flex-direction: column;
  .wo-alectify-days-name {
    border-radius: 1000px;
    border: 1px solid var(--Colors-sample-hover, #e8f1fd);
    padding: 10px;
    cursor: pointer;
  }
  .wo-alectify-days-name.active {
    background-color: #0954f1;
    color: #fff;
  }
}

.project-form-time-picker {
  width: 100%;
  height: 45px;
}

.data-rounds-basic-info-main {
  .ant-picker-panel-container {
    .ant-picker-ranges {
      display: none;
    }
  }
}

.team-member-item {
  .ant-select-selection-item {
    height: 30px;
  }
  .ant-select-single {
    height: 45px;
  }
}
