.alectify-master-project-container {
  width: 100%;
  height: calc(100vh - 191px);
  padding-right: 10px;

  .alectify-master-project-header-container {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .text-blue-bold {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #0954f1;
  }
  .ant-typography {
    margin-top: 0 !important;
  }
}
