.footer-container {
  width: 100%;
  box-sizing: border-box;

  .footer-text {
    color: #989090;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
  }
}

.ant-layout-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 5px 5px !important;
  text-align: center;
}
