@import "../../../assets/scss/index.scss";

.alectify-input-number {
  width: 100%;
  input {
    height: 45px !important;
    color: #232323;
    font-size: 14px !important;
  }

  .ant-input[type="color"] {
    height: 45px;
  }
  label {
    color: #0c0b0b;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
  }

  .ant-input-number-out-of-range .ant-input-number-input-wrap input {
    color: black !important;
  }
}
