@import "../../../assets/scss/Variables.scss";

.alectify-modal {
  .ant-modal-content {
    border-radius: 0px;
    padding: 0px;
    .ant-modal-close {
      color: white;
    }
    .ant-modal-title {
      background: $alectify-primary-blue;
      display: flex;
      height: 45px;
      padding: 20px 25px;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: white;
    }
    .ant-modal-body {
      padding: 20px 24px;
    }
    .ant-modal-footer {
      border-top: 1px solid #e4e4e4;
      padding: 16px 20px;
      margin-top: 0;
    }
  }
}

.security-banner-modal {
  .ant-modal-content {
    border-radius: 10px;
    position: absolute;
    top: 400px;
  }
}
