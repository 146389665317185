@import "../../assets/scss/Variables.scss";

.sidebar-container {
  background: #e7eefe;
  position: relative;
  z-index: 99;
}

.sidebar-container-expanded {
  background: #e7eefe;
  position: absolute;
  z-index: 99;
}
.open-logo {
  background: url("../../assets/images/fullLogo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;
}

.closed-logo {
  background: url("../../assets/images/cloudLogo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;
}
.logo-container {
  position: sticky;
  top: 0;
  z-index: 99;
  margin: 5px;
  border-radius: 16px;
}

.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: #0954f1 !important;
  display: flex;
  align-items: center;
}

.ant-menu-light > .ant-menu .ant-menu-item {
  display: flex;
  align-items: center;
}

.alectify-siderbar-container {
  .ant-menu-item {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    height: 35px;
  }
  .ant-menu-sub-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-menu-sub.ant-menu-inline {
    .ant-menu-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-menu-item-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected {
    height: 35px !important;
  }

  .ant-menu-submenu-open {
    .ant-menu-item {
      padding-left: 24px !important;
    }
  }
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected {
    height: fit-content !important;
  }

  .ant-menu-submenu-selected {
    border-radius: 6px;
    border: 1px solid #0954f1;
    background: #e8f1fd;
    margin-left: 1px;
    margin-right: 1px;
    height: 35px;
  }

  .ant-menu-item-active {
    border-radius: 6px;
    border: 1px solid #0954f1;
    background: #0954f1;
    height: 35px;
  }
  .ant-menu-item-selected {
    border-radius: 6px;
    background: #0954f1;
    height: 35px;
    .ant-menu-title-content {
      font-size: 12px;
      color: #fff !important;
    }
  }
  .alectify-sidebar-label-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px;
    .alectify-sidebar-label-text {
      font-size: 12px;
      color: $alectify-text-color;
      margin-left: 5px;
      &.active {
        color: #fff !important;
      }
      &.disabled {
        color: $alectify-disabled-grey-color;
      }
    }
  }

  .alectify-sidebar-label-icon-dropdown-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px;
    .alectify-sidebar-label-text {
      font-size: 12px;
      color: $alectify-text-color;
      margin-left: 5px;
      &.active {
        color: #0954f1 !important;
      }
      &.disabled {
        color: $alectify-disabled-grey-color;
      }
    }
  }
}

.ant-menu-submenu {
  .ant-menu-title-content {
    font-size: 12px;
  }
}

.ant-layout-sider-children {
  height: 100vh !important;
  position: relative;
}

.contact-us-item {
  border-radius: 5px;
  padding: 5px 0px !important;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3%;
  margin: 0;
}

.contantUs-modal {
  .ant-modal-content {
    .ant-modal-close {
      color: rgb(74, 74, 74) !important;
    }
  }
}

.sidebar-box {
  border-radius: 15px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  padding-top: 5px;
  background: #fff;

  .ant-menu-light {
    border-radius: 10px;
  }
}

.sidebar-dashboard-box {
  border-radius: 10px;
  margin: 5px;
  background: #fff;

  .ant-menu-light {
    border-radius: 10px;
  }
}

.sidebar-titles {
  position: sticky;
  top: 0;
  color: #052b7b;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px 10px 0 10px;
  padding-right: 4px;
  background: #fff;
  border-radius: 10px;
  z-index: 8;
  display: flex;
  justify-content: center;
  text-align: center;
}

.trigger-sidebar {
  position: absolute;
  bottom: calc(100vh - 540px);
  display: flex;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
  right: -8px;
  z-index: 9999;
}

.collapse-icon-container {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: 1px solid #a4bdf1;
  background: #dde5f6;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.alectify-siderbar-container {
  svg {
    width: 20px;
    height: 20px;
  }
}
.alectify-siderbar-container .alectify-sidebar-label-icon-container {
  height: 100%;
  width: 100%;
}

.alectify-siderbar-container {
  width: 100%;
  .label-none {
    width: 25px;
    span {
      display: none;
    }
  }
}
.label-left {
  .ant-menu-submenu-title {
    padding-left: 0 !important;
  }
}

.company-wide-options {
  .ant-menu-item-selected {
    color: #fff !important;
  }
}

.ant-menu-item-selected {
  color: #fff !important;
}

.sidebar-transparent-box {
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 15px;
  background: #e7eefe;
  width: 100%;

  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
  }
  .ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
  }
}
.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 70px;
  width: 100%;
  border-top: 1px solid #cccce6;
}

.dual-tone-text {
  background: linear-gradient(90deg, #cb06ee, #f3485c);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
