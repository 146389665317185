.success-img {
  width: 8rem;
  height: 8rem;
  flex-shrink: 0;
}

.success-text {
  color: #0954f1 !important;
  text-align: center;
}

.info-text {
  color: #353536;
  font-size: 1.1rem !important;
  font-style: normal;
  // line-height: 1.25rem;
  letter-spacing: 0.01563rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.done-button-container {
  text-align: center;

  .done-button {
    border-radius: 6250rem;
    background: #0954f1;
    width: 12rem;
    height: 3.2rem;
    font-size: 1.1rem;
    margin-top: 30px;
  }
}
