.available-selected-container {
  height: 100%;
  ul {
    padding: 0 !important;
    margin: 0 !important;
    list-style-type: none;
    max-height: calc(100vh - 211px);
    overflow-y: auto;

    li {
      padding: 10px;
      margin-top: 10px;
      border: 1px solid #dae5f3;
      border-radius: 5px;
      cursor: pointer;

      &.no-style {
        padding: 0;
        border: 0;
      }
    }
  }

  .available-selected-search {
    margin-bottom: 0;

    .ant-input-affix-wrapper {
      height: 35px;
      border: 1px solid #dae5f3;
      input {
        height: 23px !important;
      }
    }
  }
}
