.alectify-work-order-tabs {
  label {
    &:hover {
      color: #0954f1;
    }
  }
  .ant-radio-button-wrapper-checked {
    background: #0954f1 !important;
  }
}

.creator-container {
  display: flex;
  align-items: center;
  .creator-name-date {
    color: #232323;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4px;
  }
}

.status-column {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  top: -6px;
  height: 101px;
  background-color: #d92323;
}

.critical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background-color: #d92323;
  color: white;
  padding: 5px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.6px;
  line-height: normal;
}

.work-order-title {
  h4 {
    margin: 5px 0px !important;
  }
}
.alectify-wo-validation-message {
  color: red;
}
