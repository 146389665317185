.alectify-status-card {
  border-radius: 8px;
  border: 1px solid #dae5f3;
  background: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.18);
  padding: 10px;
  width: 100%;
  height: 54px;
  .status-card-text {
    font-size: 14px;
    .status-card-icon {
      svg {
        margin-top: 4px;
      }
    }
  }
  .status-card-count {
    border-radius: 100px;
    background: #f3f3f3;
    padding: 9px;
  }
  .status-card-chip {
    background: #f8d63d;
    border-radius: 5px;
    padding: 6px;
    font-size: 12px;
    white-space: nowrap;
  }
}
