@import "../../../assets/scss/Variables.scss";

.alectify-btn-block {
  width: 100%;
  height: 50px;
}

.alectify-regular-button {
  height: 37px;
  background-color: $alectify-primary-blue;
  font-size: 14px;
  height: 32px;
  padding: 0px 13px;

  .ant-btn-icon {
    vertical-align: middle;
  }
}

.alectify-default-button {
  @extend .alectify-regular-button;
  background-color: #fff !important;
  color: #000;
}

.alectify-regular-button {
  &.ant-btn-link {
    background: none;
    border: 1px solid $alectify-primary-blue;
  }
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: $alectify-primary-blue;
}

.light-blue-button-black-text {
  padding: 3px 13px;
  color: $alectify-primary-blue;
  background-color: $alectify-secondary-blue;
  color: #545454;
}

.light-grey-button {
  padding: 3px 13px;
  background-color: #84919a;
}

.danger-button {
  padding: 3px 13px;
  background-color: #fff1f1;
  border: 1px solid red;
}

.alectify-link-button {
  &.ant-btn-link {
    background: none;
    border: none !important;
  }
}

.light-blue-button-hover {
  padding: 3px 13px;
  color: $alectify-primary-blue;
  background-color: $alectify-secondary-blue !important;
  &:hover {
    border-color: $alectify-primary-blue !important;
    color: $alectify-primary-blue !important;
  }
}

.transparent-dropdown-btn {
  background-color: #ffff !important;
  color: $alectify-primary-blue !important;
}

.white-background-black-text {
  background-color: #fff !important;
  color: #000;
}

.white-background-red-text {
  background-color: #fff;
  color: red;

  &:hover {
    background-color: red !important;
    color: #fff !important;
  }
}

.blue-background-white-text {
  background-color: $alectify-primary-blue;
  color: #fff;

  &:hover {
    color: #fff !important;
  }
}
