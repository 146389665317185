@import "../../assets/scss/Variables.scss";

.ai-procedure-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .procedure-input {
    input {
      height: 55px !important;
      border-radius: 1000px;
      font-size: 15px;
      padding: 14px 37px;
    }
  }

  .question-btn {
    width: 100%;
    background: #e9eef6;
    border-radius: 30px;
    height: 60px;
    white-space: normal;
    align-items: center;
    color: #545454;

    &:hover {
      background-color: $alectify-primary-blue;
      color: #fff !important;
    }
  }
  .question-container {
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #e9eef6;
    margin-bottom: 10px;
    color: #545454;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      background-color: $alectify-primary-blue;
      color: #fff;
    }
  }

  .ai-procedure-clear-all {
    height: 100%;
    display: flex;
    align-items: flex-end;

    .ai-clear-all {
      width: 100%;
      border-radius: 125px !important;
    }
  }
}
.ai-procedure-form-container .ant-upload-drag .ant-upload {
  padding: 8px 23px;
  background: #e8f1fd;
  border-radius: 15px;
}

.alectify-procedure-upload-dnd .ant-upload-text {
  color: #676767 !important;
  text-align: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.upload-center-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.form-label {
  display: flex;
  align-items: baseline;

  .blue-round {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    margin-right: 5px;
    border-radius: 15px;
    color: #fff;
    background: #0954f1;
  }
  span {
    color: #888;
    font-size: 14px;
  }
}

.support-text-format {
  color: #676767;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
}

.ai-creator-welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #f6f8fc;
  border-radius: 25px;

  .ai-creator-summary {
    width: 40rem;

    h1 {
      display: flex;
      justify-content: center;
      color: #141b34;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      line-height: 1.4;
    }
  }
}

.input-eg-msg {
  color: #b8bcca;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.12px;
  padding: 14px 37px;
}

// AiCreatorResponseGenerator styles

.ai-response-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  .ai-response-answer-body {
    flex: 1;
    background: #f6f8fc;
    max-height: calc(100vh - 293px);
    border-radius: 25px;
    overflow: auto;
    padding: 20px 15px 15px 15px;
    margin-bottom: 10px;
  }

  .user-question {
    margin-bottom: 20px;
    .user-text {
      float: right;
      max-width: 60%;
      padding: 1.1rem;
      color: #fff;
      border-radius: 1.875rem 1.875rem 0rem 1.875rem;
      background: #0954f1;
      font-size: 0.9rem;
      overflow-wrap: break-word;
      line-height: 25px;
    }
  }
  .received-message {
    display: flex;
    .assistant-text {
      float: left;
      margin-bottom: 5px;
      line-height: 25px;
      color: #333;
      background-color: #fff;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      border-radius: 0rem 1.875rem 1.875rem 1.875rem;
      padding: 1.1rem;
    }
  }
}

.read-page-container {
  border-radius: 1.875rem;
  border: 1px solid #e5e9eb;
  background: #fff;
  padding: 10px;
  margin-top: 10px;

  .pdf-container {
    display: flex;
    border-radius: 0.8125rem;
    background: #e8f1fd;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    p {
      color: #141b35d3;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
      font-size: 13px;
    }
  }
  h3 {
    margin: 0;
    padding-left: 10px;
    padding-bottom: 10px;
    color: #545454;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    color: #0954f1;
    font-size: 0.8rem;
    font-weight: 600;
  }
}

.ai-response-generator {
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
}

.ai-footer-content {
  height: 110px;
  .bottom-detail {
    span {
      color: #888;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 10px;
    }
  }
  .pdf-container-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    border: 1px solid #e5e9eb;
    margin-top: 5px;
    background: #f6f8fc;
    height: 50px;
    padding-right: 10px;
    padding-left: 10px;

    span {
      margin-left: 6px;
      width: 100%;
    }
  }
}

.ai-creator-pdf-response {
  margin-left: 30px;
}

.equipment-info-container {
  display: flex;
  align-items: center;
  color: #545454;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 40px;
  background: #f6f8fc;
  padding: 10px;
  align-items: center;
  align-self: stretch;
  span {
    color: #141b34 m !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .bold-text {
    margin: 0 0 0 5px;
    color: #141b34;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.assistant-title {
  color: #545454;
  font-weight: 700;
  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 10px;
}

.draggable-container {
  position: fixed;
  height: 70px;
  width: 70px;
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 5px solid #fff;
  background: #fdedff;
  box-shadow: 0px 0px 21px 0px rgba(175, 24, 198, 0.35);

  .ai-drag-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: transform 0.5s ease;
    animation: rotateSlow 4s linear infinite;
    @keyframes rotateSlow {
      from {
        transform: rotateY(0deg);
      }
      to {
        transform: rotateY(360deg);
      }
    }
  }

  &:hover {
    box-shadow:
      rgba(175, 24, 198, 0.35) 0px 54px 55px,
      rgba(175, 24, 198, 0.35) 0px -12px 30px,
      rgba(175, 24, 198, 0.35) 0px 4px 6px,
      rgba(175, 24, 198, 0.35) 0px 12px 13px,
      rgba(175, 24, 198, 0.35) 0px -3px 5px;
  }
}

.attachment-text {
  padding: 10px 10px 10px 0px;
}
