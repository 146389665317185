@import "../../../assets/scss/Variables.scss";

.alectify-master-project-sidebar-container {
  .alectify-master-project-list {
    display: flex;
    padding: 10px 10px 10px 20px;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    .ant-typography {
      color: $alectify-text-color;
      font-family: $alectify-regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.084px;
      margin-bottom: 0px;
    }
    &:hover {
      background: $alectify-secondary-blue;
      div {
        color: #0954f1;
      }
    }
  }
  .alectify-master-project-list-container {
    .active-project {
      background: #f6f6f6;
      .ant-typography {
        color: #0954f1;
      }
    }
  }

  &.ant-drawer {
    z-index: 9 !important;
    .ant-input-affix-wrapper {
      border-radius: 10px;
    }
    left: 90px;
    .ant-drawer-content-wrapper {
      border-radius: 0px 20px 20px 0px;
      transform: translateX(0px) !important;
      // transition: transform 0.1s ease-in-out;
      box-shadow: 5px 1px 10px 4px #0000001c;

      .ant-drawer-content {
        border-radius: 0px 20px 20px 0px;
        border-left: 1px solid var(--grey-strokes-outlines, #dae5f3);
        background: #fff;
      }
    }
    .ant-drawer-mask {
      background: none;
      z-index: 0;
    }
  }
  .alectify-master-project-sidebar-search {
    input {
      height: 30px !important;
      padding: 10px;
    }
  }
}

.sidebar-drawer-closed {
  &.ant-drawer {
    left: 80px !important;
  }
}

.sidebar-drawer-expand {
  &.ant-drawer {
    left: 200px !important;
  }
}
