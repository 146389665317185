@import "../../../assets/scss/Variables.scss";

.alectify-description-modal-body {
  background: $alectify-secondary-bg-color;
  border-radius: 5px;
  padding: 10px;
}

.description-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .alectify-description-view-body {
    border-radius: 10px;
    background: $alectify-secondary-bg-color;
    overflow-y: auto;
    width: 100%;
    padding: 5px 18px;
    p {
      margin: 0;
    }
  }
  .more-container {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .more-button {
    cursor: pointer;
    font-size: 10px;
    transition: transform 0.3s ease;
  }

  .more-button:hover {
    color: #fff;
    transform: scale(1.1);
  }
}
