.alectify-pm-detail-left-container {
  border-radius: 13px;
  border: 1px solid var(--Grey---Strokes-Outlines, #dae5f3);
  background: #fff;
  padding: 10px;
  height: 1030px;
  .pm-detail-worktitle {
    margin-left: 62px;
  }
  .alectify-detail-card-title {
    .ant-typography {
      text-align: left;
    }
  }
}
.alectify-description-view-body {
  height: 100%;
  margin-bottom: 10px;
}

.lectify-pm-detail-right-container {
  height: 1000px;
}

.pm-detail-info-card-container {
  .pm-detail-info-card-header {
    font-size: 16px;
    font-weight: bold;
  }
  .pm-detail-info-card-body {
    color: #3e3e3e;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.14px;
    padding: 10px;
    border-radius: var(--numbers-radius-sm, 6px);
    border: 1px solid var(--Grey---Strokes-Outlines, #dae5f3);
    background: #fff;
  }
}
