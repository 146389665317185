@import "../../assets/scss/Variables.scss";

.auth-container {
  background-image: url("../../assets/images/login-bg.png");
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* This makes the background fixed while scrolling */
  height: 100vh; /* Make the background cover the entire viewport height */
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-form-container {
    height: calc(100vh - 20vh);
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(9.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 50px 75px 50px 75px;
    .auth-form-logo-container {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.41);
      background: url("../../assets/images/alectify-logo.svg"),
        rgba(255, 255, 255, 0.41);
      background-position: center;
      background-repeat: no-repeat;
      height: 110px;
      width: 110px;
    }
    .auth-form-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .auth-form-title {
        color: #000;
        font-family: $alectify-regular;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .company-name {
          color: var(--sample-blue, $alectify-primary-blue);
          font-family: $alectify-regular;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }

    .ant-form-item-control-input {
      max-height: 45px;
    }

    .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 5px;
      border-width: 2px !important;

      .ant-input {
        height: 43px;
      }
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: none;
      border-width: 2px;
    }

    .ant-input:focus,
    .ant-input-focused {
      box-shadow: none;
      border-width: 2px !important;
    }

    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper {
      border-width: 1px !important;
    }
  }
  .auth-form-copyright-text {
    color: white !important;
    display: flex;
    justify-content: center;
    margin-top: 26px;
    font-size: 14px;
  }
}
