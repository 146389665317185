@import "../../../assets/scss/Variables.scss";

.timeline-skeleton-loader {
  padding: 5px;
}

.incident-details-container {
  color: #000 !important;
  font-size: 13px;
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 9;
  margin-right: 0px !important;
  border-bottom: 1px solid #e8e8e8;
  .incident-detail-description-container {
    box-shadow: 0px 3px 14px 2px #eaf1fb;
    padding: 20px;
    border-radius: 10px;
    min-height: 215px;
    .incident-detail-description-box {
      background-color: #fff;
      //   max-height: 300px;
      overflow-y: scroll;
      border-radius: 10px;
      padding: 10px;
    }
    .incident-detail-info-value-chips {
      background-color: #ededed;
      padding: 5px;
      border-radius: 5px;
    }
  }

  .incident-statistic-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 5rem;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    gap: 6px;
    box-shadow:
      rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

    .incident-statistic-card-helper-text {
      margin-top: 2px;
      color: #969696;
    }

    &:hover {
      transform: translateY(-2px) scale(1);
      box-shadow: #609bec 0px 3px 8px;
      transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
    }

    .ant-typography-custom {
      margin: 0;
    }
  }
}

.incident-detail-comment-list {
  padding: 10px 10px 0px 20px;
  border-radius: 10px;
  background-color: #eaf1fb;
  margin-top: 10px;
  .incident-detail-comment-reply-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
  }
  .incident-statistic-card-helper-text {
    margin-top: 2px;
    color: #5b5b5b;
  }
  .ant-divider {
    border-radius: 100px;
    background: #aaa;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
  }
}
.ql-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.incident-reply-description {
  .ql-editor {
    min-height: 210px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.alectify-incident-section-card {
  .ant-picker {
    width: 100%;
    height: 45px;
  }
  .ant-select-selector {
    min-height: 45px;
  }
  .ant-input {
    border: 1px solid rgb(220, 220, 220);
    border-color: rgb(220, 220, 220) !important;
  }
  .ql-editor {
    border: 1px solid rgb(220, 220, 220);
    border-color: rgb(220, 220, 220) !important;
  }
  .ql-toolbar {
    border: 1px solid rgb(220, 220, 220) !important;
    border-bottom: none !important;
    border-color: rgb(220, 220, 220) !important;
  }
}

.incident-reports-detail-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;

  .round-tag {
    border-radius: 5px;
  }
}

.incident-detail-tags {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.incident-detail-cards {
  padding: 15px !important;
  background-color: #dfe2fc30;
}

.date-style-incident {
  font-size: 11px;
  margin-left: 4px;
  font-style: italic;
}
