.attachment-upload-container {
  display: flex;
  align-items: center;
  text-align: center;

  span {
    color: #0954f1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    margin-left: 4px;
  }
}
