@import "../../../assets/scss/Variables.scss";

.alectify-table-search-input {
  margin-bottom: 0;

  .ant-input-affix-wrapper {
    height: 35px;
    width: 266px;
    border: 1px solid var(--grey-strokes-outlines, #dae5f3);
    input {
      height: 27px !important;
    }
  }
}
.alectify-table-download-icon {
  color: #0954f1 !important;
  cursor: pointer;
  &:hover {
    background-color: #d6e2fb !important;
    border-radius: 100px !important;
    padding: 5px !important;
  }
  svg {
    height: 25px !important;
    width: 25px !important;
  }
}

.alectify-table-filters-select {
  width: 150px;
  margin: 0px;

  .ant-select-arrow {
    top: 54% !important;
  }

  .ant-select {
    height: 35px !important;
  }
}

.alectify-table-text {
  margin-bottom: 22px;
  .ant-typography {
    font-weight: $alectify-regular;
    color: $alectify-text-color;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
  }
}

.alectify-table {
  .ant-table-header {
    border: 1px solid var(--grey-strokes-outlines, #dae5f3);
    background: var(--colors-table-header-bg, #f3f3f3);
    .ant-table-cell {
      font-size: 14px;
      color: $alectify-text-color;
      background-color: #f3f3f3;

      &::before {
        background-color: #2e2e2e !important;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 14px;
    }

    .ant-table-row {
      background-color: #fff;
    }

    .ant-table-row:nth-child(odd) {
      background-color: #f2f6ff;
    }
  }
  .ant-table-cell:not(:last-child) {
    &::before {
      position: absolute;
      top: 50%;
      inset-inline-end: 0;
      width: 1px;
      height: 60%;
      background-color: #f0f0f0;
      transform: translateY(-50%);
      transition: background-color 0.2s;
      content: "";
      background-color: $alectify-border-color; // Replace with your variable
    }
  }
  .ant-table-column-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ant-table-cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .active-row {
    background: $alectify-secondary-blue !important;
  }
}

.ant-table-filter-dropdown {
  .ant-dropdown-menu-title-content {
    font-size: 14px;
    color: $alectify-text-color;
  }

  .ant-btn {
    font-size: 0.7rem;
  }
}
.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    &:hover {
      color: #fff;
      background-color: $alectify-primary-blue !important;
      svg {
        color: #fff;
      }
    }
  }
}
.ant-table-filter-dropdown {
  .ant-table-filter-dropdown-btns {
    button:first-child:disabled {
      color: $text-color-light-grey;
    }
    button:first-child {
      color: $alectify-red;
    }
  }
}

.column-filters {
  display: flex;
  align-items: center;
  margin: 5px 0px 10px 0px;
}

.alectify-table-card-columns {
  .ant-table-body {
    .ant-table-row:nth-child(odd) {
      background-color: #ffff !important;
    }
    table {
      padding: 0px 4px 0px 4px;
      border-spacing: 0px 8px;
      tr {
        height: 90px;
        border-radius: 6px;
        border: 1px solid var(--grey-strokes-outlines, #dae5f3);
        background: #fff;
        box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);
      }
      tr:last-child td:last-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      tr:first-child td {
        border-radius: 6px;
      }
      tr td:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      tr:last-child td:last-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      tr:last-child td {
        border-radius: 6px;
      }
      tr td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  &.height-auto {
    .ant-table-body {
      table {
        tr {
          height: auto;
        }
      }
    }
  }
  .ant-table-column-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ant-table-cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.bot-container {
  display: flex;
  align-items: center;
  h5 {
    margin: 0;
  }
  .bot-text {
    color: #141b34;
    font-size: 0.875rem;
    font-style: normal;
    line-height: normal;
    margin-left: 5px;
  }
}

.alectify-table-customPagition {
  position: absolute;
  bottom: 20px;
  left: 10px;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row .ant-table-cell-row-hover {
  background: $alectify-secondary-blue !important;
}

.button-active {
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  background-color: $alectify-tertiary-blue;
  border: 2px solid $alectify-primary-blue;
  color: $alectify-primary-blue;
  box-shadow: none;
  &:hover {
    color: $alectify-text-color !important;
    background-color: $alectify-tertiary-blue !important;
    border: 2px solid $alectify-primary-blue !important;
  }
}

.button-not-active {
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  border: 1px solid $alectify-border-color;
  background-color: $alectify-white;
  color: $alectify-text-color;
  box-shadow: none;

  &:hover {
    color: $alectify-text-color !important;
    background-color: $alectify-tertiary-blue !important;
    border: 1px solid $alectify-primary-blue !important;
  }
}

.table-search {
  .ant-input-affix-wrapper {
    height: 32px;
    width: 266px;
    border: 1px solid $alectify-border-color;
    box-shadow: none;
    input {
      height: 25px !important;
    }
  }
}

.table-search-active {
  .ant-input-affix-wrapper {
    color: $alectify-primary-blue !important;
    border: 1px solid $alectify-primary-blue;

    &:hover {
      border: 1px solid $alectify-primary-blue;
      box-shadow: none;
    }
  }
  path {
    fill: $alectify-primary-blue;
  }
  &:hover {
    path {
      fill: $alectify-primary-blue;
    }
  }

  .anticon {
    path {
      fill: red;
    }
  }
}

.table-search-not-active {
  .ant-input-affix-wrapper {
    &:hover {
      border-color: $alectify-primary-blue !important;
      box-shadow: 0px 2px 7px 0px $alectify-secondary-blue;
    }
  }
  path {
    fill: $alectify-secondary-text;
  }
  &:hover {
    path {
      fill: $alectify-primary-blue;
    }
  }
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 20px;

  .filter-tag {
    font-size: 11px;
    background-color: rgb(241, 240, 240);
    padding: 5px;
    border-radius: 4px;
  }
  .filter-applied-text {
    color: var(--alectify-text-light-black2);
  }
  .filter-value-text {
    color: black;
  }
}
