.spare-parts-table-modal-class {
  .ant-modal-content {
    min-height: 510px;
  }
}

.draw-spare-parts-toggle {
  .toggle-cm-pm-btn-container {
    height: 40px !important;
  }
}
