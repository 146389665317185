.analytics-card-body {
  .ant-card-body {
    padding: 4px !important;
  }

  .ant-card-head-title {
    color: #252525;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.59px;
  }
  .ant-card-head {
    padding: 0 0 0 20px !important;
  }
  .height-25 {
    height: 25rem;
  }

  .height-12 {
    height: 12rem;
  }

  .card-shadow {
    box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.11);
  }

  .margin-top-25 {
    margin-top: 25px;
  }
  .flex-direction-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    .heading {
      color: #68728c;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .count {
      color: #252525;
      font-size: 4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .icon-size-15 {
    font-size: 15px;
  }
}
