.sub-project-detail-header {
  margin: 4px 0;
  h1 {
    font-size: 1.1rem;
    margin: 0;
  }

  .light-blue-button {
    font-size: 14px;
    height: auto;
    line-height: normal;
    padding: 5px 10px;
  }
}
