.selected-week {
  background-color: blue;
  color: white;
}

.alectify-weekly-selectable-calendar {
  .alectify-weekly-calendar-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #f8f9fb;
    .my-month-select {
      width: 67px;
      margin: 5px;
      border-radius: 10px;
      padding: 4px;
      border: 1px solid #dfe1e7;
    }
  }
}

.event-box {
  background-color: #fff;
}
