.alectify-procedure-create-edit-container {
  .alectify-procedure-upload-dnd {
    .ant-upload-drag {
      height: 150px;
      border-radius: 10px;
      border: 1px dashed rgba(9, 84, 241, 0.33);
      background: var(--Colors-sample-hover, #e8f1fd);
    }
    .ant-upload-text {
      color: #676767 !important;
      text-align: center !important;
      font-family: Rubik !important;
      font-size: 13px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 18px !important; /* 138.462% */
    }
  }
  .alectify-procedure-create-edit-required {
    color: red;
  }

  .procedure-add-new-btn {
    &:hover {
      color: #0954f1 !important;
    }
  }

  .alectify-procedure-img-container {
    position: relative;
    .alectify-procedure-image {
      height: 200px;
      width: 200px;
    }
    .procedure-remove-icon {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
}

.create-procedure-body {
  width: 100%;
  .create-subProject-steps-body {
    .ant-card .ant-card-head {
      background: #eef0f2 !important;
      min-height: 48px !important;
    }
    .ant-card-head-title {
      font-size: 13px;
      color: #545454;
      font-weight: 600;
      text-align: center;
    }
    .ant-card-bordered {
      height: 100%;
      overflow: hidden;
    }
  }
  .alectify-procedure-information-container {
    height: calc(100vh - 170px);
  }
}
