@import "../../../../../assets//scss/Variables.scss";

.data-round-analytics-filter-container {
  // margin-left: 10px;
  .data-round-checkpoint-selection {
    width: 300px;
  }
  .data-round-datepicker {
    width: 300px;
    margin-bottom: 24px;
    height: 45px;
  }
  .data-round-shift-selection {
    width: 300px;
  }
}

.empty-chart-container {
  padding: 0rem 5rem 0rem 40px;
  .empty-chart-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 15rem;
    width: 100%;

    span {
      color: rgb(151, 148, 148);
    }
  }
}

.reports-chart {
  padding-top: 20px;
  border: 1px solid $alectify-border-color;
}
