.project-filter-name-and-color {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alectify-project-filter-search-input .ant-input-affix-wrapper {
  height: 35px;
  border: 1px solid var(--grey-strokes-outlines, #dae5f3);
  input {
    height: 26px !important;
  }
}
