.alectify-procedure-checklist-dnd-container {
  height: calc(100vh - 210px);
  overflow-y: auto;

  .alectify-procedure-checklist-container {
    .alectify-checklist-dnd-cards {
      background: #f5f5f5;
      margin-bottom: 12px;
      padding: 12px 0px 12px 12px;
      border-radius: 10px;
      .alectify-checklist-dnd-cards-holder-icon {
        cursor: move;
      }
      .alectify-checklist-dnd-cards-close-icon {
        cursor: pointer;
        .remove-text {
          color: red;
          text-decoration: underline;
          font-size: 12px;
        }
        .alectify-procedure-image {
          background: white;
          border: 1px solid #bfbfbf;
          filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
        }
      }
    }
  }
}
.alecitfy-procedure-steps-empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .add-new-text {
    color: #1677ff;
  }
}
