@import "../../../assets/scss/Variables.scss";

.form-heading {
  margin: 0;
  background-color: #f6f6f6;
  color: alectify-text-color !important;
  margin-left: -25px;
  margin-right: -24px;
  padding: 10px 25px;
  font-size: 1rem !important;
}

.similar-part-text {
  color: #c50000;
  margin-top: 5px;
  display: block;
  cursor: pointer;
}
