.next-break-down {
  border-radius: 0.625rem;
  background: #ff5449;
  box-shadow: 0px 2px 17px 0px #ff7f77;
  width: 9.3125rem;
  height: 3.3125rem;
  flex-shrink: 0;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightArrowIcon {
  display: flex;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.3125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 31.25rem;

  &:hover {
    background: #e8f1fd;
    svg path {
      stroke: #007bff;
    }
  }
}

.rightArrowIcon-active {
  background: #e8f1fd;
  svg path {
    stroke: #007bff;
  }
}

.rightArrowIcon-not-active {
  background: #f3f3f3;
}

.maintenance-card-container {
  padding: 5px 5px 5px 15px;
  height: 6.25rem;
  border-radius: 0.625rem;
}
.maintenance-card1 {
  background: #ff8038;
  box-shadow: 0px 2px 12px 2px rgba(255, 128, 56, 0.8);
}

.maintenance-card2 {
  background: #6d06ef;
  box-shadow: 0px 2px 12px 0px rgba(109, 6, 239, 0.76);
}

.maintenance-card3 {
  background: #ff5449;
  box-shadow: 0px 2px 12px 0px #ff7f77;
}

.text-value-style {
  color: #fff;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-style {
  color: #fff;
  font-style: normal;
  line-height: normal;
}

.task-heading {
  color: red;
  h5 {
    margin: 0 0 10px 0;
  }
}

.date-range-container {
  .ant-picker-range-separator {
    display: none;
  }
  .ant-picker-suffix {
    display: none;
  }
  .ant-picker {
    padding: 0 !important;
    border: none !important;
    input::placeholder {
      color: rgba(0, 0, 0, 0.88);
    }
  }
}

.range-picker-container {
  width: 120px;
}

.date-ranger-maintenance-picker {
  display: flex;
  align-items: center;
  margin-left: 10px;
  .date-display-text {
    font-size: 13px;
    color: #40454596;
    font-weight: 600;
    margin-right: 15px;

    span {
      font-weight: 600;
      color: #3b3b3bd3;
    }
  }
}

.display-dates-container {
  position: absolute;
  right: 0;
  justify-content: flex-end;
  display: flex;
  z-index: 9;
}
