@import "../../../assets/scss/Variables.scss";

.alectify-procedure-detail-drawer-container {
  .procedure-detail-creator-info {
    display: flex;
    justify-content: space-between;
  }
  .alectify-procedure-detail-drawer-content {
    display: flex;
    justify-self: flex-start;
    align-items: center;
    flex-direction: row;
    .alectify-detail-card-image {
      text-align: left;
      .ant-avatar {
        height: 80px;
        width: 80px;
      }
    }
    .alectify-detail-card-title {
      margin-left: 15px;
      text-align: left;
      .alectify-procedure-detail-drawer-card-text-head {
        color: var(--Colors-Font-Colors, #545454);
        font-size: 13px;
      }
    }

    .procedure-details-skeleton {
      .ant-skeleton-avatar.ant-skeleton-avatar-lg {
        width: 75px;
        height: 75px;
      }
    }
  }
  .alectify-procedure-detail-drawer-description-text {
    margin-bottom: 5px !important;
    text-align: justify;
  }
  .alectify-procedure-detail-drawer-cards-list {
    border-radius: 8px;
    border: 1px solid var(--Grey---Strokes-Outlines, #dae5f3);
    background: #fefefe;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.16);
    padding: 10px 10px 10px 10px;
    margin-bottom: 10px;
    .alectify-procedure-detail-drawer-cards-text {
      border-left: 1px solid $text-color-light-grey;
      padding-left: 15px;
    }
  }
  .alectify-procedure-steps-validation-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .alectify-procedure-detail-drawer-checklist-heading {
    font-size: 13px;
    font-weight: 600;
  }
  .alectify-procedure-checklist-check-all {
    margin-bottom: 5px;
    .ant-checkbox-inner {
      height: 20px;
      width: 20px;
      &::after {
        height: 11px;
      }
    }
  }
  .alectify-procedure-checklist-card-container {
    .checked {
      background-color: #f8fdf5 !important;
      border: 1px solid #dafcca !important;
    }
    .alectify-procedure-checklist-card {
      padding: 10px 15px;
      border-radius: 8px;
      border: 1px solid var(--Grey---Strokes-Outlines, #dae5f3);
      background: #fefefe;
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.16);
      .ant-checkbox-inner {
        height: 20px;
        width: 20px;
        &::after {
          height: 11px;
        }
      }
      .alectify-procedure-link-text {
        color: $alectify-primary-blue;
      }
      .alectify-procedure-steps-comments {
        background: #f1f1f1;
        padding: 5px;
        border-radius: 6px;
        font-size: 13px;
      }
      .alectify-procedure-comment-edit-icon {
        svg {
          height: 20px;
        }
      }
    }
    .alectify-procedure-steps-default-image {
      border-left: 1px solid lightgray;
      text-align: center;
      .text {
        font-size: 13px;
        text-decoration: underline;
        color: $alectify-primary-blue;
        cursor: pointer;
      }
    }
  }
}

/* ChecklistStatus.scss */
.checklist-container {
  width: fit-content;
  span.ant-typography {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}

.warning-review {
  background: #fcdcdc;
}

.completed-review {
  background: #b7f5b0;
}
.checklist-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 7px;
}

.warning-review-text {
  color: #ff4d4f !important;
}
.completed-review-text {
  color: #269504 !important;
}
.checklist-status-text {
  font-weight: bold;
  margin-right: 6px;
}

.checklist-warning-icon {
  color: #ff4d4f;
}

.heading-review-procedure-detail {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #f1f1f1;
  margin-top: 10px;
  margin-bottom: 10px;
  maregi span {
    font-size: 16px;
    color: #1f2d56;
    font-weight: 500;
  }
}

.card-container {
  padding: 10px 0px;
  border-radius: 4px;
  border: 1px solid #dae5f3;
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.procedure-review-date-picker {
  height: 46px !important;
}

.tag-container {
  .round-tag {
    width: fit-content !important;
  }
}
