@import "../../assets/scss/Variables.scss";

.rbc-time-view {
  flex: unset;
  .rbc-label {
    display: none;
  }

  .rbc-allday-cell {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: unset !important;
  }
  .rbc-time-content {
    display: none;
  }
}

.rbc-time-view .rbc-row {
  min-height: 26px !important;
}

.rbc-time-header-cell {
  position: sticky;
  top: 0;
  z-index: 9;
  background: #fff;
}

.rbc-calendar {
  height: calc(100vh - 150px) !important;
  .rbc-header {
    padding: 5px 3px;
  }
}
.rbc-time-header {
  height: 100%;
}

.rbc-time-header-content {
  border-left: none !important;
  overflow: auto;
  .rbc-row-segment {
    padding: 2px 4px;
  }
}

.alectify-calendar-event {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-top: 5px;

  .calendar-check-mark {
    position: absolute;
    right: 0px;
    top: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 1000px;
    z-index: 9;
  }

  .event-icon {
    border: 2px solid #d7d7d7;
    margin-right: 5px;
    background-color: #fff;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    position: relative;
    flex-shrink: 0;

    & > svg {
      width: 30px;
      height: auto;
      vertical-align: middle;
    }

    .event-status-icon {
      position: absolute;
      bottom: -16px;
      right: -4px;
    }
  }

  .event-title-normal-color {
    background-color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event-title-red-color {
    .event-title {
      strong {
        font-size: 12px;
        color: #f00707 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .event-name {
      span {
        font-size: 12px;
        color: #f00707 !important;
      }
    }
    .extra-asset {
      color: #f00707 !important;
    }
  }

  .event-title-green-color {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event-content {
    display: flex;
    flex: 1;
    padding: 4px 1px 4px 3px;
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .event-title {
      font-size: 12px;
      width: 100%;
      color: black;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-bottom: 3px;

      span {
        color: black;
        font-size: 13px;
        padding-top: 2px;
      }
    }

    .event-name {
      display: flex;
      span {
        color: black;
        font-size: 11px;
      }
    }

    .event-info {
      .ant-typography {
        color: #4f4d4d;
      }
    }
  }

  .calendar-check-mark-green {
    background: #0bb732;
  }

  .calendar-check-mark-red {
    background: #f00707;
  }
}

.alectify-calendar-toolbar {
  margin-bottom: 10px;

  .ant-radio-button-wrapper-checked {
    background-color: $alectify-primary-blue !important;
    border-color: $alectify-primary-blue !important;
  }

  .radio-btns {
    span {
      font-size: 12px !important;
    }
  }
  .title-text {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    color: rgb(60, 64, 67);
    font-size: 22px;
  }

  .alectify-calendar-toolbar-legends {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .transparent-dropdown-btn {
    color: #000 !important;
  }
}

.ant-picker-calendar-header {
  .ant-picker-calendar-mode-switch {
    display: none;
  }
}

.alectify-weekly-selectable-calendar {
  .ant-picker-cell {
    &:has(.highlight-day) {
      background-color: $alectify-secondary-blue;
    }
    .calendar-dot {
      position: absolute;
      top: 73%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background-color: $alectify-primary-blue;
      border-radius: 50%;
      border: 1px solid #fff;
      z-index: 8;
    }
  }

  .ant-picker-cell-today,
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      border-radius: 50%;

      &::before {
        display: none;
      }
    }
  }
  .hide-active-date-highlight {
    .ant-picker-cell-inner {
      color: black !important;
      background-color: transparent !important;
    }
  }

  .active-date-highlight {
    .calendar-dot {
      border: none;
      background-color: transparent !important;
    }

    .ant-picker-cell-inner {
      color: #fff !important;
      background-color: $alectify-primary-blue !important;
    }
  }
}

.masterproject-options {
  margin: 8px 0px;
  padding-bottom: 5px;
  .masterproject-text {
    flex: 1;
    max-width: calc(100% - 25px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .masterproject-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #d9e1ec;
    padding: 8px;

    .masterproject-circle-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}

.popover-col {
  margin-bottom: 5px;
}

.calender-card-tooltip-font-color {
  padding-bottom: 20px;

  .value {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    flex-shrink: 1;
  }
}

.calendar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-right: 10px;

  .breadcrumb-container {
    width: 280px;
  }
}
.rbc-time-view {
  overflow: hidden;
}
.rbc-time-header-content {
  height: 100%;
}

.calendar-content {
  display: flex;
  width: 100%;
  height: calc(100vh - 110px);
  .monthly-calendar-col {
    display: flex;
    flex-direction: column;
    width: 280px;
    flex-shrink: 0;
    height: 100%;
    background-color: #f8f9fb;
    padding: 10px;
    padding-top: 1px;

    .monthly-main {
      height: 320px;
      flex-shrink: 0;
      padding-bottom: 10px;
    }

    .calendar-filters-container {
      flex-grow: 1;
      overflow: auto;
      box-sizing: border-box;
    }
    .ant-picker-panel {
      border: none !important;
      border-radius: 10px;
    }
  }
  .weekly-calendar-col {
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 10px;
  }

  .calendar-left-main {
    display: flex;
    flex-direction: column;
  }
}

.event-container {
  border-radius: 10px;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  .popover-container-calendar {
    position: relative;
  }
  &:hover {
    transform: translateY(-5px) scale(1);
    box-shadow: 0 4px 15px $alectify-primary-blue;
  }
}

.events-popover {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  .ant-popover-content {
    .ant-popover-inner {
      padding: 20px !important;
      border-radius: 20px;
    }
  }

  .highlighted-date {
    padding: 4px 4px 4px 4px;
    background-color: #f0f8ff;
    border-left: 3px solid #0073e6;
    margin-top: 8px;
  }

  .date-strong {
    font-weight: 600;
    color: #0073e6;
  }

  .sub-heading {
    font-size: 13px;
    font-weight: normal;
  }
  .heading-sub-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    .heading {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .chips-main {
    .event-chips {
      .alectify-chip span {
        font-size: 13px !important;
      }
      .alectify-chip {
        margin-left: 0;
        box-shadow: none;
      }
    }
  }
}

.calendar-switch {
  display: flex;
  caret-color: transparent;
  width: fit-content;
  align-items: center;
  margin-left: 13px;

  .active {
    color: #fff !important;
    background: $alectify-primary-blue;
    border: 1px solid $alectify-primary-blue !important;
  }
  .border {
    border: 1px solid #ccc;
    height: 15px;
  }
  .btn-container {
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid rgb(206, 205, 205);
    padding: 0.375rem 1rem;
    border-radius: 4px;
    font-size: 13px;
    color: #373a3c;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .btn-left {
    cursor: pointer;
    border: 1px solid rgb(206, 205, 205);
    font-size: 13px;
    padding: 0.375rem 1rem;
    color: #373a3c;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
  }
}

.custom-toolbar {
  display: flex;
  align-items: center;
  right: 50%;
  justify-content: center;
}

.link-text-calendar {
  width: 100%;
  &:hover {
    color: $alectify-primary-blue !important;
    text-decoration: underline;
    text-decoration-color: $alectify-primary-blue;
  }
}

.rbc-event {
  padding: 1px 2px !important;
}
.rbc-event:focus {
  outline: none !important;
}

.rbc-overlay {
  width: 300px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 100px !important;

  .rbc-overlay-header {
    position: sticky;
    top: -10px;
    background-color: #fff;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
  }

  .link-text-calendar {
    width: 100%;
  }
  .custom-box {
    transition: all 0.3s ease;
  }

  .custom-box:hover {
    color: $alectify-primary-blue !important;
    cursor: pointer;
    text-decoration: underline;
  }
}

.toolbar-title {
  color: #3c4043;
  font-size: 20px;
  white-space: nowrap;
  margin-right: 28px;
}
.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: -5px;
  margin-right: -5px;

  &:hover {
    background: rgb(231, 238, 254);
  }
}

.title-border {
  height: 17px;
  border-radius: 10px;
  margin-right: 5px;
}

.custom-event-title {
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 100%;
}

.asset-name-span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
}

.extra-asset {
  width: 30px;
  margin-left: 4px;
  font-size: 11px;
}
