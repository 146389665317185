@import "../../../assets/scss/Variables.scss";

.comment-list {
  .ant-list-item-meta-avatar {
    margin-inline-end: 8px !important;
  }
}
.comments-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: -8px 0 5px -5px rgba(0, 0, 0, 0.12);

  .reply-body {
    margin-top: 5px;
    flex-grow: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .reduce-height {
    height: calc(100vh - 540px) !important;
  }
  .edit-box {
    flex-grow: 0;
    min-height: fit-content;
  }

  .ant-avatar-image {
    margin-top: 2px;
  }
}

.comment-attachment {
  display: inline-block !important;
  padding: 5px 10px;
  background: #eee;
  display: inline-block;
  font-size: 12px;
  color: rgb(90, 88, 88);
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
  max-width: 80%;
}

.comment-content {
  width: 80%;
  word-wrap: break-word;
  color: #333;
  font-size: 13px;

  &.me {
    background: #f3f3f3;
    border-radius: 0px 20px 20px 20px;
    padding: 10px 10px 10px 15px;
    color: #000;
  }
  &.them {
    background: #f3f3f3;
    border-radius: 0px 20px 20px 20px;
    padding: 10px 10px 10px 15px;
    color: #000;
  }
}

.date-text {
  font-size: 12px;
  color: #7c8080;
  margin-right: 5px;
  font-weight: normal;
}

.author-title {
  color: #333;
  font-size: 13px;
  font-weight: 600;
}

.editor-body {
  padding: 0px 10px;

  .attach-send-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
  }

  .comment-text-editor {
    height: 120px;
  }
  .ant-form-item {
    margin-bottom: 8px !important;
  }
  .uploaded-file-container {
    color: #6c6969;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80px;
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }
  }
}

.system-comment {
  .system-author-avatar {
    background: #e8f1fd;
    img {
      object-fit: contain;
      width: 80%;
      margin: auto;
    }
  }

  .system-author {
    color: $alectify-primary-blue;
  }

  .comment-content {
    background-color: $alectify-secondary-blue;
  }
}

.comment-item {
  display: flex;
  flex-direction: row;
  margin-left: 10px;

  .comment-content {
    margin-left: 10px;
  }

  & + .comment-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-block-end: 1px solid rgba(5, 5, 5, 0.06);
  }
}

.hide-btn-container {
  width: 40px;
  height: 30px;
  background: #e8f1fd;
  padding: 4px 4px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #fff;
  left: -4px;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  top: 7px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  // border: 1px solid #e0dede;
  border-right: none;
  // box-shadow:
  //   rgba(50, 50, 93, 0.25) 0px 6px 5px -1px,
  //   rgba(0, 0, 0, 0.3) 0px 5px 1px -3px;
  .inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(88deg, #0954f1 1%, #ff5fd6 153.41%);
    border-radius: 4px;
    height: 100%;
    padding-bottom: 3px;
  }
  .icon-rotate {
    transform: rotate(180deg);
  }
  .arrow-main {
    display: flex;
    align-items: center;
    justify-content: center;

    .first-icon {
      margin-top: 3px;
    }
    .second-icon {
      margin-left: -6px;
      margin-right: 7px;
      margin-bottom: 1px;
    }
  }
}

.audio-player-container {
  width: 100%;
}

@keyframes reverseTypingEffect {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
}

.hide-btn-container:hover .arrow-main svg {
  animation: reverseTypingEffect 1s infinite;
}

.comments-header-title {
  background-color: #e6eefe;
  padding: 10px 15px 10px 0px;
  text-align: center;
  border-top-left-radius: 10px;
  border-bottom: 1px solid #d8d0d0;
}
