.asset-detail-drawer {
  .ant-drawer-body {
    padding: 20px 12px 16px 0px;
  }
}

.profile-image-container {
  text-align: center;

  .image-wrapper {
    position: relative;
    display: inline-block;
  }

  .camera-icon {
    position: absolute;
    top: 80px;
    right: 17px;
    background-color: white;
    padding: 7px;
    border-radius: 50%;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
  }

  .contact-profile-image {
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 50%;
    object-fit: cover;
    height: 100px;
    width: 100px;
    border: 1px solid #0954f1;
  }
}
