@import "../../../assets/scss/Variables.scss";

.cards-body-insight {
  height: 100%;
}

.title-summary-container {
  margin-bottom: 10px;
  margin-top: 10px;
}

.timeline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f8fc;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;

  .round-range-picker-insight {
    height: 30px;
    margin-top: 17px;
  }
}

.data-rounds-main {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 5px;
}
