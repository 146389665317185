.alectify-chip {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 0.3px solid;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.27);
  margin-left: 5px;
  span {
    // color: #0954f1;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 !important;
  }
}

.alectify-round-chip {
  border-radius: 30px;
  padding: 3px 9px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.27);
  margin-left: 5px;
  span {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 !important;
  }
}
