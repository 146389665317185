@import "../../../assets/scss/Variables.scss";

.reports-container {
  border: 1px solid $alectify-border-color;
  border-radius: 10px;
  background: $alectify-light-blue-bg;
}

.data-report-container {
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  .ant-form-item {
    margin: 0px;
  }
}

.data-rounds-reports-form {
  width: 100%;
}

.date-picker-container {
  height: 46px;
}

.download-btn-data-rounds {
  display: flex;
  justify-content: flex-start;
}

.round-range-picker {
  height: 46px;
  width: 100%;
}

.alectify-select .ant-select-selection-placeholder {
  color: #c7c5c5 !important;
}

.customClassEventPropGetter {
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
}
.data-rounds-custom-event {
  padding-left: 10px;
  border-radius: 10px;

  span {
    color: $alectify-text-color;
    font-size: 12px;
    margin-bottom: -2px;
  }

  .border-container {
    display: inline-block;
    padding: 2px;
    height: 12px;
    border-radius: 10px;
    margin-right: 3px;
  }

  .sparrows-fire {
    background-color: #8ac2f7;
  }

  .golden-lock {
    background-color: #fc94ba;
  }

  .illicit-pink {
    background-color: #c590e4;
  }

  &:hover {
    background-color: $alectify-border-color;
    color: $alectify-primary-blue;
  }
}

.custom-toolbar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  .toolbar-label {
    color: #3c4043;
    font-size: 20px;
    white-space: nowrap;
  }
}

.data-rounds-calendar {
  height: calc(100vh - 218px) !important;

  .rbc-calendar {
    height: calc(100vh - 218px) !important;
  }
}
