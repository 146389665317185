@import "../../../assets/scss/Variables.scss";

.alectify-link-to-text {
  cursor: pointer;
  color: $alectify-primary-blue;

  &.disabled {
    cursor: default;
    color: #949494;
  }
}

.alectify-text-to-link-disabled {
  color: #949494 !important;
  cursor: not-allowed;
}
