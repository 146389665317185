@import "../../assets/scss/Variables.scss";

.company-details-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  font-size: 1rem;
  font-weight: 600;
  color: rgb(58, 58, 58);
  float: left;
}

.global-search {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.global-search-input-wrapper {
  border-radius: 11px;
  margin: 0 !important;
  border-color: #a4a4a4;

  input {
    height: 27px !important;
  }
}

.user-details-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 48px;
}

.notification-overlayClass {
  z-index: 99999;

  .ant-dropdown-menu-item {
    padding: 5px 5px !important;
  }
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
.today-task-icon {
  height: 52px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;

  & > embed {
    position: absolute;
    height: 100%;
    z-index: -9999;
  }
}

.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-logo {
  height: 41px;
}

.user-profile-modal-container {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.chatBot-icon {
  // height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chatbot {
  .ant-drawer-body {
    padding: 0 !important;
  }
}

.fortune-glass {
  height: 48px;
  width: 50px;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;

  embed {
    width: 45px;
    z-index: -999;
    height: 48px;
    cursor: pointer;
    position: absolute;
    left: 0;
  }
}

.bell-icon-container {
  border-radius: 50%;
  height: 2.4rem;
  width: 2.4rem;
  background: #e8f1fd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 6px;
  cursor: pointer;
}

.bulb-icon {
  vertical-align: middle;
  cursor: pointer;
}

.global-search-result-body {
  position: absolute;
  background-color: #e8f1fd;
  top: 50px;
  border-radius: 10px;
  width: 39.2rem;
  height: 10rem;
  right: 0;
}

.header-row {
  display: flex;
  width: 100%;
  height: 48px;
}

.bell-rotated {
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}
