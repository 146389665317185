.incident-report-creation-modal {
  .ant-modal-body {
    height: calc(100vh - 200px);
    overflow-y: scroll;

    // For small screens (e.g., mobile)
    @media (max-width: 767px) {
      height: calc(100vh - 150px); // Adjust height for small screens
    }

    // For medium screens (e.g., tablets)
    @media (min-width: 768px) and (max-width: 1024px) {
      height: calc(100vh - 180px); // Adjust height for medium screens
    }

    // For large screens (e.g., desktop)
    @media (min-width: 1025px) {
      height: calc(100vh - 220px); // Adjust height for large screens
    }
  }
}
.alectify-wo-create-edit-container {
  /*  .alectify-work-order-section-card-1 {
      padding: 25px 20px;
      border-radius: 10px;
      background: #eaf1fb;
  
      .ant-select {
        height: 32px;
      }
      .ant-select-selector {
        height: 32px !important;
      }
    } */

  .alectify-work-order-section-card {
    padding: 25px 20px;
    border-radius: 10px;
    background: #eaf1fb;
    .ant-picker {
      width: 100%;
      height: 45px;
    }
    .ant-select-selector {
      min-height: 45px;
    }
  }
  .incident-comment-reply-title {
    font-size: 14px;
    font-weight: 600;
  }
  .alectify-work-order-section-card-heading {
    color: #454545;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .alectify-work-order-section-card-counter {
    color: #b0aeae;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .wo-image-upload-container {
    display: flex;
    justify-content: center;
    .full-width-upload {
      width: 100%; // Make upload button full width
      display: flex;
      justify-content: center; // Center the content within the button
      .ant-upload-select {
        display: block;
        width: 100%;
        border-radius: 10px;
        border: 2px dashed #4f85f9;
        background: #c2d3fa;
        color: #0954f1;
        height: 63px;
        font-size: 15px;
      }
    }

    .ant-upload {
      width: 100%; // Ensures the upload area takes full width
    }
  }
  .image-preview-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .image-preview {
      position: relative;
      width: 100px;
      height: 100px;

      .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }

      .delete-icon {
        position: absolute;
        top: 4px;
        right: 4px;
        color: red;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  .wo-priority-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: #fff;
    padding: 11px 20px;
    // height: 50px;
  }
  .ql-toolbar {
    border: none !important;
    background: #fff;
    border-radius: 4px;
  }
  .wo-description {
    border: none;
    border-radius: 4px;
  }
  .wo-assets-btn {
    border-radius: 10px;
    background: #4f85f9;
    height: 58px;
    font-size: 15px;
    font-weight: 600;

    &:disabled {
      background: #b0b0b0; // Change the background color for disabled state
      cursor: not-allowed; // Optionally, show a "not-allowed" cursor
      opacity: 0.6; // Optionally, reduce opacity to indicate it's disabled
      color: #303030;
    }
  }
  .alectify-wo-divider {
    border-radius: 100px;
    background: #aaa;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .wo-team-selection-dropdown {
    min-height: 45px !important;
  }
  .ant-select-selector {
    // border-color: #fff !important;
    .team-selected {
      margin-top: 28px !important;
    }
  }
  .ant-input {
    border-color: #fff !important;
  }
  .wo-create-schedule-container {
    .wo-scheduler-type-container {
      background-color: #fff;
      padding: 15px;
      .helper-text-wo {
        color: #646161;
        font-family: Rubik;
        font-size: 13px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
      }
      .ant-select-selector {
        border-radius: 5px;
        background: #fff;
        // box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.25);
        // height: 38px;
      }
      .wo-frequency-week-selection {
        min-width: 114px !important;
      }
      .wo-frequency-day-selection {
        min-width: 114px !important;
      }
      .wo-alectify-days-name {
        border-radius: 1000px;
        border: 1px solid var(--Colors-sample-hover, #e8f1fd);
        padding: 10px;
        cursor: pointer;
      }
      .wo-alectify-days-name.active {
        background-color: #0954f1;
        color: #fff;
      }
      .wo-alectify-schedule-helper-text {
        border-radius: 4px;
        background: var(--Colors-Foreground-Screen, #f6f6f6);
        padding: 3px 10px;
        margin-top: 20px;
      }
    }
    .wo-repeat-type-selection-container {
      .ant-radio-wrapper {
        padding: 15px;
      }
    }
    .active-frequency {
      background-color: #fff;
    }
  }
  .procedure-imported-list {
    height: 650px;
  }
  .alectify-work-order-teammember-selection {
    .ant-select-selector {
      min-height: 45px;
    }
  }
}
.assets-selection-clear-all-text {
  color: red;
}

.wo-select {
  .alectify-select {
    .ant-select {
      min-width: fit-content;
    }
  }

  .ant-select-arrow {
    inset-inline-end: 5px !important;
  }
}

.incident-comment-reply-container {
  .ql-toolbar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-collapse {
    background-color: #fff;
  }
}
