.create-incident-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-upload-list {
    height: 70px !important;
    overflow: auto;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .create-incident-report-title {
    display: flex;
    align-items: center;
  }

  .create-row-container {
    flex: 1;
    gap: 12px;
    overflow: auto;
  }

  .create-col-containers {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #f6f8fc;
    padding: 10px;
  }
}

.title-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 24px;
}

.icon {
  flex-shrink: 0;
  margin-left: -20px;
  z-index: 1;
}

.incident-detail-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #dadada;
  background: #f9f9f9;
  height: calc(100vh - 110px);
  padding: 10px;
  transition: transform 0.5s ease;

  .site-container {
    color: #1f2d56;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: #e8e8e89c;
    display: inline-flex; /* Changed from flex to inline-flex */
    padding: 7px 12px;
    align-items: center;
    gap: 10px;
  }
}

.date-avatar-container {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  margin-bottom: 10px;
}

.detail-incident-box {
  background: #fff;
  margin-top: 10px;

  .quill-container {
    .ql-editor {
      height: calc(100vh - 300px);
    }
  }
}

.attachments-container {
  border-top: 1px solid rgb(219, 216, 216);
  display: flex;
  align-items: center;
  align-self: stretch;
  height: 50px;
  width: 100%;
  transition: opacity 0.3s ease-out;
  opacity: 1;
  overflow: hidden;

  &.hide {
    opacity: 0;
  }

  .files-container {
    display: flex;
    overflow-y: auto;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .attachment-tag {
    width: 150px;
    display: flex;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 15px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 1000px;
    font-size: 12px;
    background: #e8f1fd;
    color: #0954f1;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    .docIcon-container {
      width: 20px !important;
      height: 20px !important;
      margin-right: 4px;
    }

    path {
      stroke: #0954f1;
    }

    &:hover {
      color: #fff !important;
      .text-to-link-options {
        color: #fff !important;
      }
      background: #0954f1;
      transform: translateY(-2px) scale(1);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
      path {
        stroke: #fff;
      }
    }
  }

  span {
    color: #545454;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 131.1%;
  }

  .text-to-link-options {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none !important;
    &:hover {
      font-weight: 500 !important;
    }
  }

  .ant-typography u {
    text-decoration: none !important;
  }

  .docIcon-container {
    width: 20px !important;
  }
}

.create-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
}

.file-attachment-container {
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  border-radius: 9px;
  cursor: pointer;

  &:hover {
    background-color: #0954f1;
    span {
      color: #fff;
    }
    .attachment-icon path {
      stroke: #fff;
    }
  }
}

.text-editor-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.text-editor-form-item {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  .ant-typography {
    line-height: 0 !important;
    margin: 0 !important;
  }
  .detail-title-container {
    display: flex;
  }

  .drafted-container {
    color: #888181;
    font-size: 13px;
    display: flex;
    padding: 3px 9px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #e8e8e8;
    width: fit-content;
    margin-left: 4px;
  }
}

.content-centered {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .id-span {
    color: #888181;
    font-size: 14px;
    padding: 5px 12px;
    align-items: center;
    border-radius: 10px;
    background: #e8e8e89c;
  }
}

.reported-text {
  font-size: 13px;
  color: #888181;
}

.disable-container {
  pointer-events: none;
  cursor: crosshair;
  background: rgba(209, 208, 208, 0.664) !important;

  span {
    color: black !important;
  }
  .attachment-icon path {
    stroke: black;
  }
}

.active-container {
  background: #eaf1fb;
  span {
    color: #565a60;
  }
}

.attachment-count-container {
  display: flex;
  padding: 7px 15px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 1000px;
  background: #e8f1fd;
  width: fit-content;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  margin-top: 5px;
}

.attachment-count-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.incident-table-rowClassName:hover {
  .creator-name-date {
    color: #0954f1;
  }

  .CalendarDateIcon {
    path {
      fill: #0954f1;
    }
  }

  .site-name {
    color: #0954f1;
  }
  .title-name {
    color: #0954f1;
  }

  .icon-3d {
    transition:
      transform 0.3s ease,
      filter 0.3s ease;
  }

  .incident-id {
    color: #0954f1;
  }
  .icon-3d:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
  }
}
.incident-table-rowClassName-selected {
  .creator-name-date {
    color: #0954f1;
  }

  .CalendarDateIcon {
    path {
      fill: #0954f1;
    }
  }

  .site-name {
    color: #0954f1;
  }
  .title-name {
    color: #0954f1;
  }

  .icon-3d {
    transition:
      transform 0.3s ease,
      filter 0.3s ease;
  }

  .incident-id {
    color: #0954f1;
  }
  .icon-3d:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
  }
}

.incident-report-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-right: 10px;

  .content-container {
    flex: 1;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.team-background {
  background: rgba(100, 102, 102, 0.26);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.team-container {
  .ant-avatar-string {
    color: #0954f1 !important;
  }
}

.draft-box {
  border: 1px solid rgb(119, 115, 115);
  background-color: #fff;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  font-size: 12px;
  margin-left: 6px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
