.ant-tag {
  font-size: 13px !important;
  svg {
    vertical-align: middle;
    margin-bottom: 2px;
  }
}

.generic-tag {
  svg {
    width: 15px;
    vertical-align: middle;
    margin-bottom: 0px;

    path {
      fill: #fff;
    }
  }
}

.no-asset-tag {
  .text-color {
    color: #4e4e4e;
  }
  svg {
    width: 15px;
    vertical-align: middle;
    margin-bottom: 0px;

    path {
      fill: #4e4e4e;
    }
  }
}

.asset-tag {
  color: #0d76a3 !important;
  svg {
    width: 15px;
    vertical-align: middle;
    margin-bottom: 0px;

    path {
      fill: #0d76a3;
    }
  }
}

.user-type-tag {
  color: #fff !important;
  svg {
    width: 15px;
    vertical-align: middle;
    margin-bottom: 0px;

    path {
      fill: #fff;
    }
  }
}

.asset-package-tag {
  background-color: #0d76a3;
  svg {
    width: 13px;
    vertical-align: middle;
    margin-bottom: 0px;
    height: 20px;

    path {
      stroke: #fff;
    }
  }
}

.round-tag {
  border-radius: 20px;
  padding: 1px 10px;
}
.deleted-tag {
  display: flex;
  height: 28px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 10000px;
  border: 1px solid #b7b7b7;
  background: #f6f6f6;
}

.incident-tag {
  color: rgb(43, 44, 44) !important;
  border-radius: 20px;
  font-size: 13px;

  svg {
    margin: 0px;
    height: 18px;
  }
}
