.alectify-select {
  .ant-select {
    height: 45px;
  }
  .ant-select-selector {
    height: auto;
    font-size: 14px;
  }
  .ant-form-item-explain-error {
    margin-top: 10px;
  }
  .ant-select-arrow {
    // top: 70%;
  }
  .ant-select-selection-placeholder {
    color: #787878 !important;
  }
}

.ant-select-dropdown {
  .ant-select-item {
    font-size: 14px;
    padding: 5px 8px;
  }
}
