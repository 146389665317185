.quill-container {
  .ql-editor {
    overflow-y: auto;
    background-color: #fff;
  }
  p {
    margin: 0px !important;
  }
}

.attachment-container {
  text-align: left;
  width: 100%;
  display: block;

  .ant-upload-list {
    padding: 6px 6px;
    height: 70px;
    overflow: auto;
  }
}
